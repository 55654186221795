import React from 'react';

import { useTable } from '@refinedev/antd';
import { useOutletContext } from 'react-router-dom';
import { LoginEvent, UserData } from '../../../interfaces';
import { LoginHistoryTable } from '../../loginhistory/table';

export const UserLoginHistory: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();

    const { tableProps } = useTable<LoginEvent>({
        resource: 'LoginHistory',
        syncWithLocation: false,
        meta: {
            query: { userId: user.id },
            endpoint: `UserActions/${user.id}/loginhistory`
        }
    });

    return (
        <LoginHistoryTable tableProps={tableProps} />
    );
};
