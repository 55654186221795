import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, FormProps, Input, Select } from "antd";
import React from "react";
import { defaultFormLayout } from "../../constants";
import { TransactionType } from "../../interfaces";
import { getEnumOptions } from "../../utils";

export const TransactionCreateForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const accountId = formProps.initialValues?.accountId;
    const meta = {
        query: {
            accountId: accountId,
        }
    };

    const { selectProps: orderSelectProps } = useSelect({ resource: "order", optionLabel: 'displayName', meta });
    const { selectProps: positionSelectProps } = useSelect({ resource: "position", optionLabel: 'displayName', meta });

    const typeOptions = getEnumOptions(TransactionType, "TransactionType", translate, [], [TransactionType.Adjustment, TransactionType.Capital]);

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("transaction.fields.order")}
                name={"orderId"}
            >
                <Select {...orderSelectProps} />
            </Form.Item>
            <Form.Item
                label={translate("transaction.fields.position")}
                name={"positionId"}
            >
                <Select {...positionSelectProps} />
            </Form.Item>
            <Form.Item
                label={translate("transaction.fields.accountChange")}
                name={["accountChange"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("transaction.fields.type")}
                name={["type"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select options={typeOptions} />
            </Form.Item>
            <Form.Item
                label={translate("transaction.fields.description")}
                name={["description"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item name={"accountId"}>
                <Input type="hidden" />
            </Form.Item>
        </Form>
    );
};
