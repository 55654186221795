import { useTranslate } from "@refinedev/core";
import { BarElement, CategoryScale, ChartData, Colors, Legend, LinearScale, Title, Tooltip } from "chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryExposure, CategoryExposureTypes, ExposureType } from "../../../interfaces";
import { formatNumberFix, formatNumberTrim } from "../../../utils";

import './barChart.css';

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    ChartDataLabels
);

Chart.defaults.color = 'white';
Chart.defaults.borderColor = '#0a0911';
const borderColor = 'white';
const borderWidth = 1;


type ExposureChartViewProps = {
    serie: any,
    othersData: CategoryExposure[],
    labels: Array<string>,
    name: CategoryExposureTypes,
    activeStat: ExposureType,
    onSelectItem: CallableFunction,
    selection: string,
    displayDecimals: number,
    denominatedInAssetId: string,
    showHedges: boolean,
};

const backgroundColors = ["rgb(68, 157, 209)", "rgb(248, 102, 36)", "rgb(234, 53, 70)", "rgb(102, 46, 155)"];

export const ExposureBarView: React.FC<ExposureChartViewProps> = React.memo(({ showHedges, serie, labels, displayDecimals, denominatedInAssetId, name, activeStat, othersData }) => {
    const translate = useTranslate();
    const digits = displayDecimals;
    const fixedDecimals = 0;

    const otherName = translate('dashboard.fields.others');
    const totalName = translate('dashboard.fields.netHedged');
    showHedges = showHedges && name === CategoryExposureTypes.Netted;

    const tooltipFormatters = {
        totalValue: function (value: number, percentValue: string, opts: any) {
            return percentValue + '%';
        },
        openPositionCount: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
        cumulativeUnrealizedPnl: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
    }

    const dataFormatters = {
        totalValue: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K" },
        openPositionCount: function (dataValue: number) { return formatNumberTrim(dataValue, digits); },
        cumulativeUnrealizedPnl: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K" },
    }
    const activeTootipFormatter = tooltipFormatters[activeStat as keyof typeof tooltipFormatters];
    const activeDataFormatter = dataFormatters[activeStat as keyof typeof tooltipFormatters];

    const Data = serie.original;
    const DataHedge = serie.hedges;
    // labels = ['1', '2', '3', '4', '5', '6', '7', '8'];
    // const Data = [150000, -150000, 150000, -150000, 150000, -150000, 50000, -50000];
    // const DataHedge = [0, 0, -100000, 100000, -200000, 200000, 100000, -100000];

    // labels = ['Option 4'];
    // const Data = [-300000];
    // const DataHedge = [-100000];


    const getHadgeValue = (index: number): number[] => {
        const value = Data[index];
        const hedge = DataHedge[index];

        // Good, subtract the hedge from the value
        if (hedge <= 0 && value >= 0 || hedge >= 0 && value <= 0) {
            if (Math.abs(hedge) > Math.abs(value)) {
                return [0, value];
            } else {
                return [-hedge, 0];
            }
        }
        // Bad, add the hedge to the value
        if (hedge <= 0 && value <= 0) {
            return [0.0001, hedge]; // Hack: if zero - it stacks above value
        }
        if (hedge >= 0 && value >= 0) {
            return [-0.0001, hedge]; // Hack: if zero - it stacks above value
        }

        return [0, 0];
    };
    const getTotalValue = (index: number): number[] => {
        const value = Data[index];
        const hedge = DataHedge[index];

        // Good, subtract the hedge from the value
        if (hedge <= 0 && value >= 0 || hedge >= 0 && value <= 0) {
            return [0, value + hedge];
        }
        // Bad, add the hedge to the value
        if (hedge <= 0 && value <= 0) {
            return [hedge, value + hedge];
        }

        if (hedge >= 0 && value >= 0) {
            return [hedge, hedge + value];
        }

        return [0, 0];
    };

    // Prepare hadge data: bars should be stacked and aligned accoring to the total value +/- hedge
    const series = useMemo(() => {
        let dataset: any[] = [];
        if (showHedges) {
            const barData = Data.map((item: number, index: number) => getTotalValue(index));
            const hedgeData = DataHedge.map((item: number, index: number) => getHadgeValue(index));

            dataset = [
                { data: barData, label: translate(`dashboard.fields.netted`), backgroundColor: backgroundColors, stack: 'a', borderColor: borderColor, borderWidth: borderWidth, borderSkipped: false, originalData: Data },
                { data: hedgeData, label: translate(`dashboard.fields.hedge`), backgroundColor: ["#333"], stack: 'a', borderColor: borderColor, borderWidth: borderWidth, borderSkipped: false, originalData: DataHedge, hidden: !showHedges },
            ];

        } else {
            dataset = [{ data: serie.data, label: translate(`dashboard.fields.${name}`), backgroundColor: backgroundColors, borderColor: borderColor, borderWidth: borderWidth, borderSkipped: false, originalData: Data }];
        }

        return dataset;
    }, [serie, name, showHedges]);

    const chartData: ChartData<'bar', number[][]> = {
        labels: labels,
        datasets: series
    };

    // value can be range or plain numeric
    const multyValue = (value: number | number[] | undefined) => {
        if (!value) {
            return 0;
        }
        if (Array.isArray(value)) {
            return value[1] - value[0];
        }
        return value;
    }

    return (
        <div style={{ width: '100%', height: '300px' }}>
            <Bar
                data={chartData}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            stacked: showHedges,
                            grid: {
                                color: '#333',
                            },
                        },
                        y: {
                            stacked: showHedges,
                            ticks: {
                                callback: function (val: string | number) {
                                    return activeDataFormatter(val as number);
                                },
                            },
                            grid: {
                                lineWidth:function (context) {
                                    return context.tick.value === 0 ? 2 : 1;
                                },
                                color: function (context) {
                                    if (context.tick.value === 0) {
                                        return 'grey';
                                    }

                                    return '#333';
                                },
                            },
                        }
                    },
                    plugins: {
                        datalabels: {
                            color: 'white',
                            textShadowColor: 'black',
                            textShadowBlur: 3,
                            borderColor:'red',
                            font: {
                                weight: 'bold'
                            },
                            formatter: (value: any, context: any) => {
                                const val = multyValue(value);
                                if (!val) {
                                    return null;
                                }

                                return activeDataFormatter(val);
                            }
                        },
                        legend: {
                            position: 'bottom',
                            display: false,
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function (tooltipItem: any) {
                                    if (tooltipItem.label === otherName) {
                                        return othersData.map(row => row.categoryId + ': ' + activeDataFormatter(tooltipItem.datasetIndex == 0 ? row[name][activeStat] : row.hedgeValue));
                                    }
                                    const value = tooltipItem.dataset.originalData[tooltipItem.dataIndex];
                                    return tooltipItem.dataset.label + ': ' + activeDataFormatter(value);
                                },
                                footer: function (tooltipItems: any) {
                                    if (showHedges) {
                                        const items = tooltipItems.map((t: any) => t.dataset.originalData[t.dataIndex]);
                                        const value = items.reduce((a: number, b: number) => a + b, 0);
                                        return totalName + ': ' + activeDataFormatter(value);
                                    }
                                },
                            }
                        }
                    }
                }}
            />
        </div>
    )
});

ExposureBarView.displayName = 'ExposureBarView';