import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { DataProvider } from "@refinedev/core";
import { axiosInstance, generateSort, generateFilter } from "./utils";
import { BaseRecord } from "@refinedev/core/dist/contexts/data/types";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Omit<
    Required<DataProvider>,
    "createMany" | "updateMany" | "deleteMany"
> => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        const endpoint = meta?.endpoint || meta?.baseEndpoint || resource;

        const url = `${apiUrl}/${endpoint}`;

        const {
            current = 1,
            pageSize = 10,
            mode = "server",
        } = pagination ?? {};

        const { headers: headersFromMeta, method, query: queryFromMeta } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const queryFilters = generateFilter(filters);

        const query: {
            _skip?: number;
            _take?: number;
            _sortdesc?: string;
            _sort?: string;
        } = { ...queryFromMeta };

        if (mode === "server") {
            query._skip = (current - 1) * pageSize;
            query._take = pageSize;
        }

        const generatedSort = generateSort(sorters);
        if (generatedSort) {
            const { _sort, _sortdesc } = generatedSort;
            if (_sort.length !== 0) {
                query._sort = _sort.join(",");
            }
            if (_sortdesc.length !== 0) {
                query._sortdesc = _sortdesc.join(",");
            }
        }

        const { data, headers } = await httpClient[requestMethod](
            `${url}?${stringify(query)}&${stringify(queryFilters)}`,
            {
                headers: headersFromMeta,
            },
        );

        const retData = meta?.dataClass ? data.map((item: BaseRecord) => new meta.dataClass(item)) : data;

        const total = +headers["x-total-count"];

        return {
            data: retData,
            total: total || data.length,
        };
    },

    getMany: async ({ resource, ids, meta }) => {
        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        let { data } = await httpClient[requestMethod](
            `${apiUrl}/${resource}?${stringify({ id: ids })}`,
            { headers },
        );

        if (meta?.dataClass) {
            data = data.map((item: BaseRecord) => new meta.dataClass(item));
        }

        return {
            data,
        };
    },

    create: async ({ resource, variables, meta }) => {
        const endpoint = meta?.endpoint || resource;

        const url = `${apiUrl}/${endpoint}`;

        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "post";

        const { data } = await httpClient[requestMethod](url, variables, {
            headers,
        });

        return {
            data,
        };
    },

    update: async ({ resource, id, variables, meta }) => {
        const objValues = variables as { [key: string]: string };
        const parts = (id+"").split(',');
        const encodedId = parts.map(encodeURIComponent).join('/');
        const updateCanonical = async () => {
            const url = `${apiUrl}/${resource}/${encodedId}/canonical`;
            const patchValues = Object.keys(objValues).map((key: string) => {
                return {
                    op: 'replace',
                    path: key,
                    value: objValues[key]
                }
            });

            const { headers, method } = meta ?? {};
            const requestMethod = (method as MethodTypesWithBody) ?? "patch";

            const { data } = await httpClient[requestMethod](url, patchValues, {
                headers: headers ? headers : { 'Content-Type': 'application/json-patch+json' },
            });
            return { data };
        }
        
        const updateForm = async () => {
            let url: string;
            if (meta?.endpoint) {
                url = `${apiUrl}/${meta.endpoint}`;
            } else {
                url = `${apiUrl}/${resource}?${encodedId}`;
            }

            const { headers, method } = meta ?? {};
            const requestMethod = (method as MethodTypesWithBody) ?? "patch";
            const params = new URLSearchParams();
            
            Object.keys(objValues).forEach((key: string) => {
                params.append(key, objValues[key]);
            });

            const { data } = await httpClient[requestMethod](url, params, {
                headers: headers ? headers : { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            return { data };
        }

        const updateJSON = async () => {
            let url: string;
            if (meta?.endpoint || meta?.editEndpoint) {
                const endpoint = meta?.endpoint || meta?.editEndpoint;
                url = `${apiUrl}/${endpoint}`;
            } else {
                url = `${apiUrl}/${resource}?${encodedId}`;
            }

            const { headers, method } = meta ?? {};
            const requestMethod = (method as MethodTypesWithBody) ?? "post";

            const { data } = await httpClient[requestMethod](url, objValues, {
                headers: headers ? headers : { 'Content-Type': 'application/json' },
            });
            return { data };
        }

        if (meta?.form) {
            return updateForm();
        } else if (meta?.json) {
            return updateJSON();
        } else {
            return updateCanonical();
        }
    },

    getOne: async ({ resource, id, meta }) => {
        const { headers, method, query: queryFromMeta } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const query: Record<string, never> = { ...queryFromMeta };
        const parts = (id+"").split(',');
        const encodedId = parts.map(encodeURIComponent).join('/');

        let url: string;
        if (meta?.endpoint) {
            url = `${apiUrl}/${meta.endpoint}?${stringify(query)}`;
        } else {
            if (meta?.baseEndpoint) {
                url = `${apiUrl}/${meta.baseEndpoint}/${encodedId}?${stringify(query)}`;
            } else {
                url = `${apiUrl}/${resource}/${encodedId}?${stringify(query)}`;
            }
        }

        let { data } = await httpClient[requestMethod](url, { headers });

        if (meta?.dataClass) {
            data = new meta.dataClass(data);
        }

        return {
            data,
        };
    },

    deleteOne: async ({ resource, id, variables, meta }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "delete";

        const { data } = await httpClient[requestMethod](url, {
            data: variables,
            headers,
        });

        return {
            data,
        };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({
        url,
        method,
        filters,
        sorters,
        payload,
        query,
        headers,
    }) => {
        let requestUrl = `${url}?`;

        if (sorters) {
            const generatedSort = generateSort(sorters);
            if (generatedSort) {
                const { _sort, _sortdesc } = generatedSort;
                const sortQuery = {
                    _sort: _sort.join(","),
                    _sortdesc: _sortdesc.join(","),
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
              axiosResponse = await httpClient[method](url, payload, {
                headers,
              });
              break;
            case "delete":
              axiosResponse = await httpClient.delete(url, {
                data: payload,
                headers: headers,
              });
              break;
            default:
              axiosResponse = await httpClient.get(requestUrl, {
                headers,
              });
              break;
          }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
