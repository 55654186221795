import React from "react";
import { AlertOutlined, DashboardOutlined, DollarOutlined, FileSearchOutlined, FormOutlined, GoldOutlined, HistoryOutlined, MenuUnfoldOutlined, PercentageOutlined, PlayCircleOutlined, QuestionCircleOutlined, ReconciliationOutlined, RetweetOutlined, RiseOutlined, SecurityScanOutlined, SettingOutlined, SwapOutlined, SyncOutlined, TeamOutlined, TransactionOutlined, UserOutlined, UserSwitchOutlined, WifiOutlined } from "@ant-design/icons";
import { ResourceProps } from "@refinedev/core";
import { AccountData, LendingDTO, OrderDTO, PositionDTO, StaffUserAccessControl, UserData } from "./interfaces";

export const resources: ResourceProps[] = [
  {
    name: "settings",
    meta: {
      permission: "SystemSetupPermitted",
      label: "Market Data",
      icon: <SettingOutlined />,
    }
  },
  {
    name: "asset",
    list: "/asset",
    create: "/asset/create",
    edit: "/asset/:id/edit",
    show: "/asset/:id/show/:tab",
    meta: {
      permission: "SystemSetupPermitted",
      icon: <GoldOutlined />,
      parent: "settings",
    }
  },
  {
    name: "assetClass",
    list: "/assetClass",
    create: "/assetClass/create",
    edit: "/assetClass/:id/edit",
    show: "/assetClass/:id/show",
    meta: {
      permission: "SystemSetupPermitted",
      icon: <GoldOutlined />,
      parent: "settings",
    }
  },
  {
    name: "lending",
    list: "/asset/:id/lending",
    create: "/asset/:assetId/lending/create",
    edit: "/asset/:assetId/lending/edit/:id",
    show: "/asset/:assetId/lending/show/:id",
    meta: {
      permission: "CrmAccessPermitted", // TODO: WritePermission = ApiPolicyPermission.MarketManagementPermitted
      icon: <DollarOutlined />,
      dataClass: LendingDTO,
      canDelete: true,
      parent: "asset",
      hide: true,
    },
  },
  {
    name: "pair",
    list: "/pair/list",
    create: "/pair/create",
    edit: "/pair/edit/:id",
    show: "/pair/show/:id",
    meta: {
      permission: "SystemSetupPermitted",
      icon: <SwapOutlined />,
      canDelete: true,
      parent: "settings",
    },
  },
  {
    name: "pairCategory",
    list: "/pairCategory/list",
    create: "/pairCategory/create",
    edit: "/pairCategory/edit/:id",
    show: "/pairCategory/show/:id",
    meta: {
      permission: "SystemSetupPermitted",
      icon: <MenuUnfoldOutlined />,
      parent: "settings",
    },
  },
  {
    name: "exchange",
    list: "/exchange/list",
    create: "/exchange/create",
    edit: "/exchange/edit/:id",
    show: "/exchange/show/:id",
    meta: {
      permission: "SystemSetupPermitted",
      canDelete: true,
      icon: <RetweetOutlined />,
      parent: "settings",
    },
  },
  {
    name: "fees",
    meta: {
      permission: "FeeManagementPermitted",
      icon: <PercentageOutlined />
    },
  },
  {
    name: "override",
    list: "/override/list",
    create: "/override/create",
    edit: "/override/edit/:id",
    show: "/override/show/:id",
    meta: {
      parent: "fees",
      permission: "FeeManagementPermitted",
      canDelete: true,
      icon: <PercentageOutlined />
    },
  },
  {
    name: "fee",
    list: "/fee/list",
    create: "/fee/create",
    edit: "/fee/edit/:id",
    meta: {
      parent: "fees",
      permission: "FeeManagementPermitted",
      canDelete: true,
      icon: <PercentageOutlined />
    },
  },
  {
    name: "exposure",
    meta: {
      permission: "RiskManagementPermitted",
      label: "Exposure",
      icon: <RiseOutlined />,
    }
  },
  {
    name: "exposure",
    list: "/",
    meta: {
      permission: "RiskManagementPermitted",
      parent: "exposure",
      label: 'Exposure Dashboard',
      icon: <DashboardOutlined />
    },
  },
  {
    name: "breaches",
    list: "/exposure/breaches",
    meta: {
      endpoint: "exposure/unknown/breaches",
      permission: "RiskManagementPermitted",
      parent: "exposure",
      icon: <SecurityScanOutlined />,
    },
  },
  {
    name: "utilization",
    list: "/exposure/utilization",
    meta: {
      endpoint: "exposure/{aggrType}/utilization",
      permission: "RiskManagementPermitted",
      parent: "exposure",
      icon: <SyncOutlined />,
    },
  },
  {
    name: "risk",
    list: "/exposure/risk",
    meta: {
      permission: "RiskManagementPermitted",
      parent: "exposure",
      icon: <QuestionCircleOutlined />,
    },
  },
  {
    name: "brokerVaR",
    meta: {
      endpoint: "exposure/brokerVaR",
      permission: "RiskManagementPermitted",
    },
  },
  {
    name: "brokerRatios",
    meta: {
      endpoint: "exposure/brokerRatios",
      permission: "RiskManagementPermitted",
    },
  },
  {
    name: "riskfreerates",
    meta: {
      endpoint: "exposure/riskfreerates",
      permission: "RiskManagementPermitted",
    },
  },
  {
    name: "latestEntry",
    meta: {
      endpoint: "exposure/LatestEntry",
      permission: "RiskManagementPermitted",
    },
  },
  {
    name: "entryHistory",
    meta: {
      endpoint: "exposure/EntryHistory",
      permission: "RiskManagementPermitted",
    },
  },
  {
    name: "margin_manangement",
    list: "/management/margin",
    meta: {
      endpoint: "MarginManangement/AccountsMargins",
      permission: "MarginManagementPermitted",
      parent: "exposure",
      label: 'Margin accounts',
      icon: <RiseOutlined />,
    },
  },
  {
    name: "marginCall",
    list: "/marginCall",
    meta: {
      query: {
        _include: ['account', 'policy']
      },
      parent: "exposure",
      permission: "RiskManagementPermitted",
      icon: <RiseOutlined />
    },
  },
  {
    name: "marginCallPolicy",
    list: "/marginCallPolicy/list",
    create: "/marginCallPolicy/create",
    edit: "/marginCallPolicy/edit/:id",
    show: "/marginCallPolicy/show/:id",
    meta: {
      parent: "exposure",
      permission: "RiskManagementPermitted",
      canDelete: true,
      icon: <RiseOutlined />
    },
  },
  {
    name: "accountPairOverride",
    meta: { hide: true, permission: "FeeManagementPermitted", form: true },
  },
  {
    name: "accountAssetClassOverride",
    meta: { hide: true, permission: "FeeManagementPermitted", form: true },
  },
  {
    name: "accountGroupAssetClassOverride",
    meta: { hide: true, permission: "FeeManagementPermitted", form: true },
  },
  {
    name: "accountGroupPairOverride",
    meta: { hide: true, permission: "FeeManagementPermitted", form: true },
  },

  {
    name: "user_management",
    meta: {
      permission: "CrmAccessPermitted",
      label: "Users and Accounts",
      icon: <UserOutlined />,
    }
  },
  {
    name: "user",
    list: "/user/list",
    create: "/user/create",
    edit: "/user/edit/:id",
    show: "/user/show/:id/:tab",
    meta: {
      parent: "user_management",
      permission: "UserManagementPermitted",
      canDelete: true,
      dataClass: UserData,
      icon: <UserOutlined />,
      query: {
        _include: ['userGroup',]
      }
    },
  },
  {
    name: "document",
    list: "/document",
    meta: {
      hide: true,
      permission: "CrmAccessPermitted",
      icon: <GoldOutlined />,
      parent: "user",
    }
  },
  {
    name: "bankAccount",
    edit: "/user/show/:userId/bankAccounts/edit/:id",
    show: "/user/show/:userId/bankAccounts/show/:id",
    meta: {
      hide: true,
      permission: "CrmAccessPermitted",
      icon: <GoldOutlined />,
      parent: "user",
    }
  },
  {
    name: "account",
    list: "/account/list",
    create: "/account/create",
    edit: "/account/edit/:id",
    show: "/account/show/:id/:tab",
    meta: {
      identifier: 'account',
      parent: "user_management",
      permission: "CrmAccessPermitted",
      dataClass: AccountData,
      canDelete: true,
      icon: <UserSwitchOutlined />,
      query: {
        _include: ['accountGroup']
      }
    },
  },
  {
    name: "account",
    identifier: 'account_search',
    meta: {
      hide: true,
      permission: "CrmAccessPermitted",
      dataClass: AccountData,
    },
  },
  {
    name: "accountGroup",
    list: "/accountGroup/list",
    create: "/accountGroup/create",
    edit: "/accountGroup/edit/:id",
    show: "/accountGroup/show/:id",
    meta: {
      parent: "user_management",
      permission: "CrmAccessPermitted",
      icon: <UserSwitchOutlined />,
      query: {
        _include: ['marginCallPolicy']
      }
    },
  },
  {
    name: "userGroup",
    list: "/userGroup/list",
    create: "/userGroup/create",
    edit: "/userGroup/edit/:id",
    show: "/userGroup/show/:id",
    meta: {
      parent: "user_management",
      permission: "CrmAccessPermitted",
      icon: <TeamOutlined />,
    },
  },
  {
    name: "staffUserAccessControl",
    list: "/staffUser/list",
    create: "/staffUser/create",
    edit: "/staffUser/edit/:id",
    show: "/staffUser/show/:id",
    meta: {
      parent: "user_management",
      permission: "CrmAccessPermitted",
      icon: <TeamOutlined />,
      dataClass: StaffUserAccessControl,
      query: {
        _include: ['staffUser', 'managedUserGroup']
      }
    },
  },
  {
    name: "trading",
    meta: {
      permission: "UserManagementPermitted",
      label: "Order Book",
      icon: <TransactionOutlined />,
    }
  },
  {
    name: "orders",
    meta: {
      permission: "UserManagementPermitted",
      parent: "trading",
      label: "Orders",
      icon: <FormOutlined />
    },
  },
  {
    name: "open_order",
    list: "/open_order/list",
    create: "/open_order/create",
    edit: "/open_order/edit/:id",
    show: "/open_order/show/:id",
    meta: {
      editEndpoint: 'useractions/updateOrder',
      json: true,
      dataClass: OrderDTO,
      parent: "orders",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <FormOutlined />,
      query: {
        _include: ['pair']
      }
    },
  },
  {
    name: "order",
    list: "/order/list",
    show: "/order/show/:id",
    meta: {
      editEndpoint: 'useractions/updateOrder',
      json: true,
      dataClass: OrderDTO,
      parent: "orders",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <FormOutlined />,
      query: {
        _include: ['pair']
      }
    },
  },
  {
    name: "positions",
    meta: {
      permission: "UserManagementPermitted",
      parent: "trading",
      label: "Positions",
      icon: <PlayCircleOutlined />
    },
  },
  {
    name: "open_position",
    list: "/open_position/list",
    edit: "/open_position/edit/:id",
    show: "/open_position/show/:id",
    meta: {
      json: true,
      editEndpoint: 'useractions/updatePosition',
      dataClass: PositionDTO,
      baseEndpoint: "position",
      parent: "positions",
      permission: "UserManagementPermitted",
      permissions: {
        close: "RiskManagementPermitted"
      },
      canDelete: false,
      icon: <PlayCircleOutlined />
    },
  },
  {
    name: "position",
    list: "/position/list",
    show: "/position/show/:id",
    meta: {
      label: "Closed positions",
      json: true,
      editEndpoint: 'useractions/updatePosition',
      dataClass: PositionDTO,
      parent: "positions",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <PlayCircleOutlined />
    },
  },
  {
    name: "alerts",
    meta: {
      permission: "UserManagementPermitted",
      parent: "trading",
      label: "Alerts",
      icon: <AlertOutlined />
    },
  },
  {
    name: "active_alert",
    list: "/active_alert/list",
    edit: "/active_alert/edit/:id",
    show: "/active_alert/show/:id",
    meta: {
      json: true,
      editEndpoint: 'useractions/updateAlert',
      baseEndpoint: "alert",
      parent: "alerts",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <AlertOutlined />
    },
  },
  {
    name: "alert",
    list: "/alert/list",
    show: "/alert/show/:id",
    meta: {
      editEndpoint: 'useractions/updateAlert',
      json: true,
      parent: "alerts",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <AlertOutlined />
    },
  },
  {
    name: "transaction",
    list: "/transaction/list",
    // create: "/transaction/create",
    // edit: "/transaction/edit/:id",
    show: "/transaction/show/:id",
    meta: {
      parent: "trading",
      permission: "UserManagementPermitted",
      canDelete: false,
      icon: <TransactionOutlined />
    },
  },
  {
    name: "accounting",
    list: "accounting",
    meta: {
      permission: "CrmAccessPermitted",
      canDelete: false,
      endpoint: "accounting/report",
      icon: <ReconciliationOutlined />
    },
  },
  {
    name: "sessions",
    list: "/sessions",
    meta: {
      parent: "user_management",
      permission: "SystemSetupPermitted",
      canDelete: false,
      endpoint: "crminfo/CurrentSessions",
      icon: <WifiOutlined />
    },
  },
  {
    name: "loginhistory",
    list: "/loginhistory",
    meta: {
      parent: "user_management",
      permission: "SystemSetupPermitted",
      canDelete: false,
      endpoint: "crminfo/loginhistory",
      icon: <HistoryOutlined />
    },
  },
  {
    name: "log",
    list: "/log",
    meta: {
      parent: "user_management",
      permission: "SystemSetupPermitted",
      canDelete: false,
      icon: <FileSearchOutlined />
    },
  },
  {
    name: "OpenPosition",
    meta: {
      hide: true,
    },
  },
  {
    name: "OpenOrders",
    meta: {
      hide: true,
    },
  },
  {
    name: "ActiveAlerts",
    meta: {
      hide: true,
    },
  },
  {
    name: "reference",
    meta: {
      hide: true,
    }
  },
  {
    name: "orders_pairs",
    meta: {
      hide: true,
      endpoint: "order/reference/pairId"
    }
  },
  {
    name: "orders_accounts",
    meta: {
      hide: true,
      endpoint: "order/reference/accountId"
    }
  },
  {
    name: "positions_pairs",
    meta: {
      hide: true,
      endpoint: "position/reference/pairId"
    }
  },
  {
    name: "positions_accounts",
    meta: {
      hide: true,
      endpoint: "position/reference/accountId"
    }
  },
  {
    name: "alerts_pairs",
    meta: {
      hide: true,
      endpoint: "alert/reference/pairId"
    }
  },
  {
    name: "alerts_accounts",
    meta: {
      hide: true,
      endpoint: "alert/reference/accountId"
    }
  },
  {
    name: "crminfo",
    meta: {
      hide: true,
    }
  },
  {
    name: "openpositionssummary",
    list: "/open_position/list",
    meta: {
      hide: true,
      endpoint: "marketbook/openpositionssummary"
    },
  },
  {
    name: "openpositions",
    meta: {
      hide: true,
      endpoint: "marketbook/openpositions"
    },
  },
  {
    name: "openorderssummary",
    meta: {
      hide: true,
      endpoint: "marketbook/openorderssummary"
    },
  },
  {
    name: "openorders",
    meta: {
      hide: true,
      endpoint: "marketbook/openorders"
    },
  },
  {
    name: "activealertssummary",
    meta: {
      hide: true,
      endpoint: "marketbook/activealertssummary"
    },
  },
  {
    name: "activealerts",
    meta: {
      hide: true,
      endpoint: "marketbook/activealerts"
    },
  },
  {
    name: "pair/info",
    meta: {
      hide: true,
    },
  },
];