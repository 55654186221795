import React from 'react';

import { useTable } from '@refinedev/antd';
import { Account, LoginEvent } from '../../../interfaces';
import { LogTable } from '../../log';
import { useOutletContext } from 'react-router-dom';

export const AccountLog: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();

    const { tableProps, sorters } = useTable<LoginEvent>({
        resource: 'log',
        syncWithLocation: false,
        meta: {
            query: { accountId: account.id },
        }
    });

    return (
        <LogTable tableProps={tableProps} sorters={sorters} showUser={false} showAccount={false} />
    );
};
