import {
    useTranslate
} from "@refinedev/core";
import { Descriptions, DescriptionsProps, Popover, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { LogoField, NumberField, UnitValueField } from "../../../components/fields";
import { AggregationType, CategoryExposure, CategoryExposureTypes, Exposure, ExposureType } from "../../../interfaces";

type ExposureTableViewProps = {
    data: any,
    activeStat: ExposureType,
    name: CategoryExposureTypes,
    displayDecimals: number,
    assetType: AggregationType,
    isLoading: boolean,
    onSelectItem: CallableFunction,
    selection: string,
    setSorter: CallableFunction,
    sorter: {
        order: string | null,
        field: string[]
    },
    showHedges: boolean,
    denominatedInAssetId: string
}

export const ExposureTableView: React.FC<ExposureTableViewProps> = React.memo(({ data, showHedges, displayDecimals, denominatedInAssetId, activeStat, name, assetType, isLoading, onSelectItem, selection, setSorter, sorter }) => {
    const translate = useTranslate();
    const digits = displayDecimals;

    // const items: MenuProps['items'] = [
    //     {
    //         key: '1',
    //         label: (
    //             <Link to="fee/list">
    //                 {translate('fee.fees')}
    //             </Link>
    //         ),
    //     },
    //     {
    //         key: '2',
    //         label: (
    //             <Link to="marginCall">
    //                 {translate('marginCall.marginCalls')}
    //             </Link>
    //         ),
    //     },
    //     {
    //         key: '3',
    //         label: (
    //             <Link to="marginCallPolicy/list">
    //                 {translate('marginCallPolicy.marginCallPolices')}
    //             </Link>
    //         ),
    //     },
    // ];

    const getRowClassName = (record: CategoryExposure) => {
        const classes = [];
        if (record.categoryId === selection) {
            classes.push('highlighted-row')
        }
        classes.push('position-' + calculateShortLong(record));

        return classes.join(' ');
    }

    const calculateShortLong = (record: CategoryExposure) => {
        if (name === 'netted') {
            return record[name].totalValue < 0 && record[name].totalNotional < 0 ? 'short' : 'long';
        }
        return name;
    }

    const HedgeInfo = ({ record, value }: { record: CategoryExposure, value: number }) => {
        const items: DescriptionsProps['items'] = [
            {
                key: 'netted',
                label: translate('dashboard.fields.netted'),
                children: <UnitValueField value={record[name].totalValue} digits={digits} unit={denominatedInAssetId} />,
            },
            {
                key: 'hedge',
                label: translate('dashboard.fields.hedge'),
                children: <UnitValueField value={record.hedgeValue} digits={digits} unit={denominatedInAssetId} />,
            },
            {
                key: 'total',
                label: translate('dashboard.fields.total'),
                children: <UnitValueField value={record[name].totalValue + record.hedgeValue} digits={digits} unit={denominatedInAssetId} />,
            },
        ]
        const popupContent = <Descriptions items={items} layout="horizontal" column={1} size="small" />
        return <Popover content={popupContent}><UnitValueField value={value} digits={digits} unit={calculateShortLong(record) === 'short' ? 'S' : 'L'} /></Popover>;
    }

    const columns = [
        {
            title: translate('dashboard.fields.category'),
            dataIndex: ['categoryId'],
            key: 'categoryId',
            align: "left",
            sortOrder: sorter.field.join('.') === 'categoryId' ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a.categoryId.localeCompare(b.categoryId),
            render: (value: any) => <LogoField id={value} type={assetType} name={value} />
            // <Space><LogoField id={value} type={category} name={value} /><Dropdown menu={{ items }} placement="bottomLeft"><Button size='small'>...</Button></Dropdown></Space>
        },
        {
            title: translate('dashboard.fields.totalValue'),
            dataIndex: [name, 'totalValue'],
            key: 'totalValue',
            hidden: showHedges,
            align: "right",
            sortOrder: sorter.field.join('.') == [name, 'totalValue'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a[name].totalValue - b[name].totalValue,
            render: (value: any, record) => <HedgeInfo value={value} record={record} />
        },
        {
            title: translate('dashboard.fields.totalValue'),
            dataIndex: [name, 'totalValue'],
            key: 'totalValueHadge',
            align: "right",
            hidden: !showHedges,
            sortOrder: sorter.field.join('.') == [name, 'totalValue'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a[name].totalValue - b[name].totalValue,
            render: (value: any, record) => <HedgeInfo value={value + record.hedgeValue} record={record} />
        },
        {
            title: translate('dashboard.fields.openPositionCount'),
            dataIndex: [name, 'openPositionCount'],
            key: 'openPositionCount',
            align: "right",
            sortOrder: sorter.field.join('.') == [name, 'openPositionCount'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a[name].openPositionCount - b[name].openPositionCount,
            render: (value: any) => <NumberField value={value} />
        },
        {
            title: translate('dashboard.fields.cumulativeUnrealizedPnl'),
            dataIndex: [name, 'cumulativeUnrealizedPnl'],
            key: 'cumulativeUnrealizedPnl',
            align: "right",
            sortOrder: sorter.field.join('.') == [name, 'cumulativeUnrealizedPnl'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a[name].cumulativeUnrealizedPnl - b[name].cumulativeUnrealizedPnl,
            render: (value: any) => <UnitValueField value={value} digits={digits} />
        },
        {
            title: translate('dashboard.fields.hedge'),
            dataIndex: ['hedgeValue'],
            key: 'hedgeValue',
            align: "right",
            hidden: !showHedges,
            sortOrder: sorter.field.join('.') == ['hedge'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a.hedgeValue - b.hedgeValue,
            render: (value: any) => <NumberField value={value} digits={digits} />
        },
        {
            title: translate('dashboard.fields.totalNotional'),
            dataIndex: [name, 'totalNotional'],
            key: 'totalNotional',
            align: "right",
            hidden: assetType === AggregationType.AssetClass,
            sortOrder: sorter.field.join('.') == [name, 'totalNotional'].join('.') ? sorter.order : null,
            sorter: (a: CategoryExposure, b: CategoryExposure) => a[name].totalNotional - b[name].totalNotional,
            render: (value: any) => <NumberField value={value} />
        },
    ] as (ColumnType<CategoryExposure> & { hidden?: boolean })[];

    const onChange = (pagination: any, filters: any, s: SorterResult<CategoryExposure> | SorterResult<CategoryExposure>[]) => {
        const sr = s as SorterResult<CategoryExposure>;
        setSorter({ order: sr.order, field: sr.field });
    }
    let sortedData = [...data];

    if (sorter.order && sorter.field.length > 1) {
        sortedData = sortedData.sort((a: CategoryExposure, b: CategoryExposure) => {
            const ae = a[sorter.field[0] as CategoryExposureTypes];
            const be = b[sorter.field[0] as CategoryExposureTypes];
            const res = ae[sorter.field[1] as ExposureType] - be[sorter.field[1] as ExposureType];
            return sorter.order === 'ascend' ? res : -res;
        });
    }

    return (<>
        <Table
            size='small'
            className='wrap-normal'
            // scroll={{ x: 'max-content' }}
            tableLayout='fixed'
            showSorterTooltip={false}
            onChange={onChange}
            // onRow={(record: CategoryExposure) => {
            //     return {
            //         onMouseEnter: () => { onSelectItem(name, record.categoryId); },
            //         onMouseLeave: () => { onSelectItem(name, ''); },
            //     };
            // }}
            rowClassName={(record: CategoryExposure, index: number) => getRowClassName(record)}
            rowKey={'categoryId'}
            dataSource={sortedData}
            columns={columns.filter(column => !column.hidden)}
            pagination={false}
            summary={(_: any) => {

                let totalTotalValue = 0;
                let totalOpenPositionCount = 0;
                let totalTumulativeUnrealizedPnl = 0;

                data.forEach((row: CategoryExposure) => {
                    const exp = row[name as CategoryExposureTypes] as Exposure;
                    totalTotalValue += exp.totalValue;
                    totalOpenPositionCount += exp.openPositionCount;
                    totalTumulativeUnrealizedPnl += exp.cumulativeUnrealizedPnl;
                });

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>{translate('common.total')}</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align="right">
                                <UnitValueField value={totalTotalValue} digits={digits} unit='&nbsp;&nbsp;' />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align="right">
                                <NumberField value={totalOpenPositionCount} />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4} align="right">
                                <UnitValueField value={totalTumulativeUnrealizedPnl} digits={digits} />
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    </>)
});