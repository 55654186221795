import { getDefaultSortOrder, ShowButton, useModalForm } from "@refinedev/antd";
import {
    BaseRecord,
    useTranslate
} from "@refinedev/core";
import { BaseKey, CrudFilter, CrudSort } from "@refinedev/core/dist/contexts/data/types";
import { Modal, Space, Table, TableProps } from "antd";
import React from "react";
import { DateField, EnumField, LogoPairField, NumberField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useArrayFilter } from '../../hooks/useArrayFilter';
import { useReferenceData } from '../../hooks/useReferenceFilter';
import { useTableClick } from '../../hooks/useTableClick';
import { Account, ExecutionMethod, Position, PositionSide, PositionStatus } from "../../interfaces";
import { getEnumFilter } from "../../utils";
import { PositionForm } from './form';

interface PositionsTableProps {
    withIdentity: boolean;
    sorters: CrudSort[];
    filters: CrudFilter[];
    tableProps: TableProps;
}

export const ClosedPositionsTable: React.FC<PositionsTableProps> = (props) => {
    const translate = useTranslate();

    const statusFilter = getEnumFilter(PositionStatus, 'PositionStatus', translate, ["Unknown"]);
    const sideFilter = getEnumFilter(PositionSide, 'PositionSide', translate, ["Unknown"]);
    const methodFilter = getEnumFilter(ExecutionMethod, 'ExecutionMethod', translate, ["Unknown"]);

    const pairFilters = useArrayFilter({ resource: 'positions_pairs', labelName: 'id' })

    const accountsHash = useReferenceData({ resource: 'account', ids: props.tableProps.dataSource?.map((item: Position) => item.accountId) });
    const pairsHash = useReferenceData({ resource: 'pair/info', ids: props.tableProps.dataSource?.map((item: Position) => item.pairId) });
    const accountAssetHash = useReferenceData({ resource: 'asset', ids: props.tableProps.dataSource?.map((item: Position) => item.identity?.denominatedIn).filter((id: string | undefined) => id) as BaseKey[] });

    const accountAsset = (record: Position) => {
        if (record.identity?.denominatedIn === undefined) {
            return undefined;
        }
        return accountAssetHash.get(record.identity?.denominatedIn);
    }


    const getRowClassName = (record: Position) => {
        return 'default-action-show clickable-row position-' + (record.side === PositionSide.Long ? 'long' : 'short');
    }

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
    } = useModalForm<Position>({
        resource: "position",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    const { sorters } = props;

    const clickTableProps = useTableClick({ resource: 'position', action: 'show' });
    return <>
        <Table
            {...props.tableProps}
            rowKey="id"
            className="wrap-normal"
            {...clickTableProps}
            rowClassName={(record: Position) => getRowClassName(record)}
        >
            <Table.Column
                dataIndex="token"
                title={translate("position.fields.token")}
            />
            <Table.Column
                dataIndex={["pairId"]}
                title={translate("position.fields.pair")}
                render={(value) => <LogoPairField pairId={value} />}
                filterSearch={true}
                filters={pairFilters}
            />
            <Table.Column
                dataIndex="status"
                filters={statusFilter}
                title={translate("position.fields.status")}
                render={(value: PositionStatus) => <EnumField enum="PositionStatus" value={value} translate={translate} />}
            />
            <Table.Column
                align="end"
                dataIndex="notional"
                sorter={true}
                title={translate("position.fields.notional")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.baseSymbol} padRight={2} />}
            />
            <Table.Column
                dataIndex="side"
                title={translate("position.fields.side")}
                render={(value: PositionSide) => <EnumField enum="PositionSide" value={value} translate={translate} />}
                filters={sideFilter}
            />
            <Table.Column
                align="end"
                dataIndex="realizedPnl"
                title={translate("position.fields.realizedPnl")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountAsset(record)?.symbol} digits={accountAsset(record)?.displayDecimals || 2} />}
            />
            <Table.Column
                dataIndex={["closedAt"]}
                title={translate("position.fields.closedAt")}
                render={(value: string) => { return value && <DateField value={value} format="LLL" /> }}
            />

            <Table.Column
                align="end"
                dataIndex="entranceCost"
                title={translate("position.fields.entranceCost")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountAsset(record)?.symbol} digits={accountAsset(record)?.displayDecimals || 2} />}
            />

            <Table.Column
                align="end"
                dataIndex="marginRatio"
                title={translate("position.fields.marginRatio")}
                render={(value: number) => <NumberField value={value} digits={2} />}
            />

            {props.withIdentity && <Table.Column
                dataIndex={["accountId"]}
                title={translate("position.fields.account")}
                render={(value) => <AccountField value={accountsHash.get(value) as Account} showIdentity={false} link="positions" />}
            />}

            <Table.Column
                align="end"
                dataIndex="method"
                filters={methodFilter}
                onFilter={(value, record: Position) => record.method === value}
                sorter={(a: Position, b: Position) => a.method > b.method ? 1 : -1}
                title={translate("position.fields.method")}
                render={(value: number) => <EnumField value={value} enum="ExecutionMethod" translate={translate} />}
            />
            <Table.Column
                align="end"
                dataIndex="connectorId"
                sorter={(a: Position, b: Position) => a.connectorId > b.connectorId ? 1 : -1}
                title={translate("position.fields.connectorId")}
            />
            <Table.Column
                dataIndex={["createdAt"]}
                title={translate("position.fields.createdAt")}
                sorter={true}
                defaultSortOrder={getDefaultSortOrder("createdAt", sorters)}
                render={(value: string) => <DateField value={value} format="LLL" />}
            />
            <Table.Column
                align="end"
                dataIndex="actions"
                render={(_, record: BaseRecord) => (
                    <Space>
                        {/* <EditButton
                            hideText
                            size="small"
                            resource="position"
                            recordItemId={record.id}
                            disabled={record.status !== "Open"}
                            onClick={() => {
                                editModalShow(record.id);
                            }}
                        /> */}
                        <ShowButton
                            hideText
                            size="small"
                            resource="position"
                            recordItemId={record.id}
                        />
                        {/* {record.status == "Open" && <ClosePositionButton
                            hideText
                            size="small"
                            resource="position"
                            
                            ids={[record.id]}
                            confirmOkText={translate("management_margin.buttons.confirm_cancel")}
                        />} */}
                    </Space>
                )}
            />
        </Table>
        <Modal {...editModalProps}>
            <PositionForm formProps={editFormProps} />
        </Modal>
    </>
};


