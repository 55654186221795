import React from 'react';
import { useAssetClassData } from '../../hooks/data';
import { AssetClassesEnum } from '../../interfaces';

const api_url = `${import.meta.env.VITE_BACKEND_URL}api`;

type LogoType = 'pair' | 'asset' | 'category' | 'assetClass';


export const LogoAssetField: React.FC<{ assetId: string, name: string }> = ({ assetId, name }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`${api_url}/logoimage/asset?assetId=${assetId}`} />
            </span>
        </div>
        <span>{name}</span>
    </span>
};

export const LogoPairField: React.FC<{ pairId: string }> = ({ pairId }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`${api_url}/logoimage/pair?pairId=${pairId}`} />
            </span>
        </div>
        <span>{pairId}</span>
    </span>
};

export const LogoAssetClassField: React.FC<{ token: AssetClassesEnum }> = ({ token }) => {
    const hash = useAssetClassData();
    return <LogoCategoryField token={token.toString()} name={hash.get(token)?.name} type="assetClass" />
};

export const LogoCategoryField: React.FC<{ token: string, type: string, name?: string }> = ({ token, type, name }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`${api_url}/logoimage/category?type=${type}&token=${token}`} />
            </span>
        </div>
        <span>{name}</span>
    </span>
};


export const LogoField: React.FC<{ type: LogoType, id: unknown, name?: string, category?: string }> = ({ type, id, name, category }) => {
    if (type === 'pair') {
        return <LogoPairField pairId={id as string} />
    } else if (type === 'asset') {
        return <LogoAssetField assetId={id as string} name={name!} />
    } else if (type === 'category') {
        return <LogoCategoryField token={id as string} name={name!} type={category!} />
    } else if (type === 'assetClass') {
        return <LogoAssetClassField token={(typeof id === 'string' ? parseInt(id) : id) as AssetClassesEnum} />
    }
    console.error('Unknown type: ' + type);
    return <>---</>
};

