import {
    TextField, useSelect
} from "@refinedev/antd";
import {
    useCreate, useTranslate, useUpdate
} from "@refinedev/core";
import { Form, Select } from "antd";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { LogoCategoryField, LogoPairField } from "../../components/fields/Logo";
import i18n from "../../i18n";
import { Account, AccountAssetClassOverride, AccountData, AccountGroup, AccountGroupAssetClassOverride, AccountGroupPairOverride, AccountPairOverride, AssetClass, AssetClassesEnum, Override, Pair } from "../../interfaces";
import { getEnumOptions } from "../../utils";

export enum OverrideType {
    Unknown = "Unknown",
    AccountPair = "AccountPair",
    AccountAssetClass = "AccountAssetClass",
    AccountGroupAssetClass = "AccountGroupAssetClass",
    AccountGroupPair = "AccountGroupPair",
    AssetClass = "AssetClass",
    Account = "Account",
    Pair = "Pair",
}

type FeeOverrideDataType = AccountPairOverride | AccountGroupPairOverride | AccountAssetClassOverride | AccountGroupAssetClassOverride | Account | AssetClass | Pair;

export class RenderContext {
    getOverride(overrideId: number) {
        return this.overrides.get(overrideId);
    }
    getAccount(accountId: number) {
        return this.accounts.get(accountId);
    }
    getPair(pairId: string) {
        return this.pairs.get(pairId);
    }
    getAccountGroup(groupId: number) {
        return this.accoutGroups.get(groupId);
    }

    init(overrides: Override[] | undefined, accounts: AccountData[] | undefined, pairs: Pair[] | undefined, accoutGroups: AccountGroup[] | undefined, translate: TFunction) {
        if (overrides) {
            overrides.forEach(override => this.overrides.set(override.id, override));
        }
        if (accounts) {
            accounts.forEach(account => this.accounts.set(account.id, account));
        }
        if (pairs) {
            pairs.forEach(pair => this.pairs.set(pair.id, pair));
        }
        if (accoutGroups) {
            accoutGroups.forEach(ag => this.accoutGroups.set(ag.id, ag));
        }
        this.translate = translate;
    }

    overrides: Map<number, Override> = new Map();
    accounts: Map<number, AccountData> = new Map();
    pairs: Map<string, Pair> = new Map();
    accoutGroups: Map<number, AccountGroup> = new Map();
    translate: TFunction = i18n.t;
}

export const renderContext = new RenderContext();

const accountDataRender = (data: AccountPairOverride | AccountAssetClassOverride, context: RenderContext) => {
    const account = context.getAccount(data.accountId)
    return account ? account.displayName : '';
}

const accountRender = (data: Account, context: RenderContext) => {
    const account = context.getAccount(data.id)
    return account ? account.displayName : '';
}

const accountGroupRender = (data: AccountGroupPairOverride | AccountGroupAssetClassOverride, context: RenderContext) => {
    const ag = context.getAccountGroup(data.accountGroupId);
    return ag ? ag.name : '';
}

const pairInfoRender = (pairId: string) => {
    return <LogoPairField pairId={pairId} />
}

const pairDataRender = (data: AccountGroupPairOverride | AccountPairOverride, context: RenderContext) => {
    return pairInfoRender(data.pairId);
}

const pairRender = (data: Pair, context: RenderContext) => {
    return pairInfoRender(data.id);
}

const assetClassInfoRender = (name: string, id: string) => {
    return <LogoCategoryField token={id} type={"AssetClass"} name={name} />
}

const assetClassDataRender = (data: AccountGroupAssetClassOverride | AccountAssetClassOverride, context: RenderContext) => {
    const strId = data.assetClassId + "";
    return assetClassInfoRender(context.translate(strId), strId);
}

const assetClassRender = (data: AssetClass, context: RenderContext) => {
    return assetClassInfoRender(context.translate(data.name), data.name);
}

const noneRender = () => '';

const resourceMapper = {
    [OverrideType.Unknown]: {
        assetName: '',
        accountName: '',
        accountDisplay: noneRender,
        assetDisplay: noneRender,
        assetId: (record: FeeOverride): string => '',
        accountId: (record: FeeOverride): number => 0,
        accountType: (record: FeeOverride): string => '',
        assetType: (record: FeeOverride): string => '',
        resource: '',
        id: (record: FeeOverride) => '',
        values: (record: FeeOverride) => { return {}; },
        updateOnly: true,
        search: () => { return true },
    },
    [OverrideType.AccountPair]: {
        assetName: 'pairId',
        accountName: 'accountId',
        accountDisplay: accountDataRender,
        assetDisplay: pairDataRender,
        assetId: (record: FeeOverride): string => (record.data as AccountPairOverride).pairId,
        accountId: (record: FeeOverride) => (record.data as AccountPairOverride).accountId,
        accountType: (record: FeeOverride): string => 'Account',
        assetType: (record: FeeOverride): string => 'Pair',
        resource: 'accountPairOverride',
        updateOnly: false,
        search: (data: AccountPairOverride, value: string) => { return data.pairId.toLowerCase().includes(value) }
    },
    [OverrideType.AccountAssetClass]: {
        assetName: 'assetClassId',
        accountName: 'accountId',
        accountDisplay: accountDataRender,
        assetDisplay: assetClassDataRender,
        assetId: (record: FeeOverride): string => (record.data as AccountAssetClassOverride).assetClassId + "",
        accountId: (record: FeeOverride) => (record.data as AccountAssetClassOverride).accountId,
        accountType: (record: FeeOverride): string => 'Account',
        assetType: (record: FeeOverride): string => 'AssetClass',
        resource: 'accountAssetClassOverride',
        updateOnly: false,
        search: (data: AccountAssetClassOverride, value: string) => { return data.assetClassId.toString().toLowerCase().includes(value) }
    },
    [OverrideType.AccountGroupAssetClass]: {
        assetName: 'assetClassId',
        accountName: 'accountGroupId',
        accountDisplay: accountGroupRender,
        assetDisplay: assetClassDataRender,
        assetId: (record: FeeOverride): string => (record.data as AccountGroupAssetClassOverride).assetClassId + "",
        accountId: (record: FeeOverride): number => (record.data as AccountGroupAssetClassOverride).accountGroupId,
        accountType: (record: FeeOverride): string => 'AccountGroup',
        assetType: (record: FeeOverride): string => 'AssetClass',
        resource: 'accountGroupAssetClassOverride',
        updateOnly: false,
        search: (data: AccountGroupAssetClassOverride, value: string) => { return data.assetClassId.toString().toLowerCase().includes(value) }
    },
    [OverrideType.AccountGroupPair]: {
        assetName: 'pairId',
        accountName: 'accountGroupId',
        accountDisplay: accountGroupRender,
        assetDisplay: pairDataRender,
        assetId: (record: FeeOverride): string => (record.data as AccountGroupPairOverride).pairId,
        accountId: (record: FeeOverride): number => (record.data as AccountGroupPairOverride).accountGroupId,
        accountType: (record: FeeOverride): string => 'AccountGroup',
        assetType: (record: FeeOverride): string => 'Pair',
        resource: 'accountGroupPairOverride',
        updateOnly: false,
        search: (data: AccountGroupPairOverride, value: string) => { return data.pairId.toLowerCase().includes(value) }
    },
    [OverrideType.Account]: {
        assetName: '',
        accountName: 'id',
        accountDisplay: accountRender,
        assetDisplay: noneRender,
        assetId: (record: FeeOverride): string => '',
        accountId: (record: FeeOverride): number => (record.data as Account).id,
        accountType: (record: FeeOverride): string => 'Account',
        assetType: (record: FeeOverride): string => '',
        resource: 'account',
        updateOnly: true,
        search: (data: Account, value: string) => { return data.token.toLowerCase().includes(value) }
    },
    [OverrideType.Pair]: {
        assetName: 'id',
        accountName: '',
        accountDisplay: noneRender,
        assetDisplay: pairRender,
        assetId: (record: FeeOverride): string => (record.data as Pair).id,
        accountId: (record: FeeOverride): number => 0,
        accountType: (record: FeeOverride): string => '',
        assetType: (record: FeeOverride): string => 'Pair',
        resource: 'pair',
        updateOnly: true,
        search: (data: Pair, value: string) => { return data.id.toLowerCase().includes(value) }
    },
    [OverrideType.AssetClass]: {
        assetName: 'id',
        accountName: '',
        accountDisplay: noneRender,
        assetDisplay: assetClassRender,
        assetId: (record: FeeOverride): string => (record.data as AssetClass).id.toString(),
        accountId: (record: FeeOverride): number => 0,
        accountType: (record: FeeOverride): string => '',
        assetType: (record: FeeOverride): string => 'AssetClass',
        resource: 'assetClass',
        updateOnly: true,
        search: (data: AssetClass, value: string) => { return data.name.toLowerCase().includes(value) }
    },
}

export class FeeOverride {
    overrideFee: Override;
    overrideFeeId: number;
    type: OverrideType;
    data: FeeOverrideDataType;
    updated: Date = new Date();
    edit: boolean = false;
    cache: Array<boolean> = [];
    constructor(data: any) {
        this.overrideFeeId = data["overrideFeeId"];
        this.overrideFee = data["overrideFee"];
        this.type = data["type"];
        this.data = data["data"];
    }

    search(filterValue: string): boolean {
        return this.mapInfo.search(this.data as never, filterValue);
    }

    static assetFilter(type: OverrideType, data: FeeOverride, value: string): boolean {
        const [k1, k2] = value.toString().split(':');
        return resourceMapper[type].assetId(data) == k2;
    }

    static accountFilter(type: OverrideType, data: FeeOverride, value: string): boolean {
        const [k1, k2] = value.toString().split(':');
        return resourceMapper[type].accountId(data).toString() == k2 && resourceMapper[type].accountType(data) == k1;
    }

    static assetDisplay(data: FeeOverride, context: RenderContext): string | JSX.Element {
        return data.type ? resourceMapper[data.type].assetDisplay(data.data as any, context) : "";
    }
    static accountDisplay(data: FeeOverride, context: RenderContext): string | JSX.Element {
        return data.type ? resourceMapper[data.type].accountDisplay(data.data as any, context) : "";
    }
    get mapInfo() {
        return resourceMapper[this.type];
    }
    static getMapInfo(type: OverrideType) {
        return resourceMapper[type];
    }

    get id(): string {
        return this.type !== OverrideType.Unknown ? this.type + '-' + this.mapInfo.accountId(this) + '-' + this.mapInfo.assetId(this) : 'new';
    }



    get updateOnly(): boolean {
        return this.mapInfo.updateOnly;
    }

    static getRecordId(data: any = null): string {
        if (!data) {
            return 'new';
        }
        const res = this.getMapInfo(data.type);
        if (!res.assetName) {
            return encodeURIComponent(data ? data['accountId'] : res.accountId(data));
        }

        if (!res.accountName) {
            return encodeURIComponent(data ? data['assetId'] : res.assetId(data));
        }

        return `${res.assetName}=${encodeURIComponent(res.assetId(data))}&${res.accountName}=${res.accountId(data)}`;
    }

    get resource(): string {
        return this.mapInfo.resource;
    }

    get assetId(): string | number {
        return this.mapInfo.assetId(this);
    }

    get accountId(): number {
        return this.mapInfo.accountId(this);
    }

    get assetName(): string {
        return this.mapInfo.assetName;
    }

    get accountName(): string {
        return this.mapInfo.accountName;
    }
}

export const useFeeProps = (

) => {
    const translate = useTranslate();

    const assetClassSelectOptions = getEnumOptions(AssetClassesEnum, 'AssetClassesEnum', translate, ['Unknown']);

    // TODO: optitimize for big databases
    const { selectProps: pairSelect } = useSelect<Pair>({ resource: "pair", optionLabel: "id", optionValue: "id", pagination: { pageSize: 100 } });
    const { selectProps: accountSelect } = useSelect<AccountData>({ resource: "account", optionLabel: "displayName", optionValue: "id" });
    const { selectProps: accountGroupSelect } = useSelect<AccountGroup>({ resource: "accountGroup", optionLabel: "name", optionValue: "id" });
    const { selectProps: assetClassSelect } = useSelect<AssetClass>({ resource: "assetClass", optionLabel: "name", optionValue: "id" });

    const AssetClassesEnumSelect = { options: assetClassSelectOptions };

    const [form] = Form.useForm();
    const { mutate: updateMutate } = useUpdate();
    const { mutate: createMutate } = useCreate();

    const [allData, setAllData] = useState<FeeOverride[]>([]);
    const [editId, setId] = React.useState<string>("");

    const isEditing = (id: string) => id === editId;

    const cancelEdit = () => {
        setId((cancelId: string) => {
            if (cancelId == 'new') {
                allData.forEach(data => {
                    data.edit = false;
                });
                const dataSource = [...allData];
                dataSource.splice(0, 1);
                setAllData(dataSource);
            }
            return "";
        });
    }

    const cancelEditClick = (record: FeeOverride) => {
        if (record) {
            record.edit = false;
            record.cache = new Array<boolean>(6).fill(true);
        }
        cancelEdit();
    }

    const editButtonClick = (record: FeeOverride) => {
        form.setFieldsValue({
            type: record.type,
            overrideFeeId: record.overrideFeeId,
            assetId: record.assetId,
            accountId: record.accountId,
        });
        record.edit = true;
        record.cache = new Array<boolean>(6).fill(true);

        cancelEdit();
        setId(record.id);
    };

    const createButtonProps = () => {
        return {
            disabled: editId !== '',
            onClick: () => {
                const newItem = new FeeOverride({
                    overrideFee: null,
                    overrideFeeId: null,
                    type: OverrideType.Unknown,
                    data: null,
                });
                newItem.edit = true;
                const dataSource = [newItem, ...allData];
                form.resetFields();

                setAllData(dataSource);
                setId("new");
            },
        }
    }

    const saveButtonProps = (record: FeeOverride) => {
        return {
            onClick: async () => {
                try {
                    const row = (await form.validateFields());
                    const rowData = { ...record, ...row };
                    const feeData = new FeeOverride({ ...record, ...row });

                    if (editId === 'new' && !feeData.updateOnly) {
                        createMutate({
                            // errorNotification: () => {return {type: "error", "message": 'Error'}},
                            successNotification: () => { cancelEdit(); return { type: "success", "message": translate('fee.messages.created') } },
                            resource: feeData.resource,
                            values: {
                                overrideFeeId: feeData.overrideFeeId,
                                [feeData.assetName]: row.assetId,
                                [feeData.accountName]: row.accountId,
                            },
                        });
                    } else {
                        if (feeData.updateOnly) {
                            updateMutate({
                                successNotification: () => { cancelEdit(); return { type: "success", "message": translate('fee.messages.updated') } },
                                resource: feeData.resource,
                                values: { overrideFeeId: feeData.overrideFeeId },
                                id: FeeOverride.getRecordId(rowData),
                            });
                        } else {
                            updateMutate({
                                successNotification: () => { cancelEdit(); return { type: "success", "message": translate('fee.messages.updated') } },
                                resource: feeData.resource,
                                values: { overrideFeeId: feeData.overrideFeeId },
                                id: FeeOverride.getRecordId(rowData),
                            });
                        }
                    }
                } catch (errInfo) {
                    console.log('Validate Failed:', errInfo);
                }
            },
        };
    }

    // TODO: delete only for intermediate tables, for the entities use update only!
    const deleteButtonProps = (record: FeeOverride) => {
        return {
            resource: record.resource,
            recordItemId: FeeOverride.getRecordId(record),
        };
    };

    const renderAssetData = (data: FeeOverride, context: RenderContext) => {
        if (isEditing(data.id)) {
            const typeValue = form.getFieldValue('type')
            const { assetProps } = getSelectProps(typeValue);
            if (!assetProps) {
                return <></>
            }
            return (
                <Form.Item name="assetId" style={{ margin: 0 }} rules={[{ required: true, },]}>
                    <Select size="small" {...assetProps} />
                </Form.Item>
            );
        }
        return <TextField className="flex" value={FeeOverride.assetDisplay(data, context)} />
    }

    const renderUsersData = (data: FeeOverride, context: RenderContext) => {
        if (isEditing(data.id)) {
            const typeValue = form.getFieldValue('type')
            const { accountProps } = getSelectProps(typeValue);
            if (!accountProps) {
                return <></>
            }
            return (
                <Form.Item name="accountId" style={{ margin: 0 }} rules={[{ required: true, },]}>
                    <Select size="small" {...accountProps} />
                </Form.Item>
            );
        }
        return <TextField className="flex" value={FeeOverride.accountDisplay(data, context)} />
    }

    const getSelectProps = (type: OverrideType) => {
        if (type === OverrideType.AccountPair) {
            return { accountProps: accountSelect, assetProps: pairSelect };
        } else if (type === OverrideType.AccountAssetClass) {
            return { accountProps: accountSelect, assetProps: AssetClassesEnumSelect };
        } else if (type === OverrideType.AccountGroupAssetClass) {
            return { accountProps: accountGroupSelect, assetProps: AssetClassesEnumSelect };
        } else if (type === OverrideType.AccountGroupPair) {
            return { accountProps: accountGroupSelect, assetProps: pairSelect };
        } else if (type === OverrideType.Account) {
            return { accountProps: accountSelect, assetProps: null };
        } else if (type === OverrideType.Pair) {
            return { accountProps: null, assetProps: pairSelect };
        } else if (type === OverrideType.AssetClass) {
            return { accountProps: null, assetProps: assetClassSelect };
        }
        return { accountProps: null, assetProps: null };
    }

    const changeOverrideType = (value: string, record: FeeOverride) => {

        form.setFieldsValue({
            'accountId': null,
            'assetId': null,
            'type': value,
        });
        const newData = [...allData];
        setAllData(newData);
    }

    return {
        isEditing,
        data: allData,
        form,
        renderAssetData,
        createButtonProps,
        setData: setAllData,
        assetClassSelectOptions,
        changeOverrideType,
        renderUsersData,
        saveButtonProps,
        cancelEditClick,
        editButtonClick,
        deleteButtonProps
    };
};
