import React from "react";
import { useTranslate } from "@refinedev/core";
import { Form, Input, FormProps } from "antd";
import { defaultFormLayout } from "../../constants";

export const PositionForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    return (
        <Form 
        {...formProps} 
        {...defaultFormLayout}
        >
           <Form.Item
                label={translate("position.fields.totalStopLoss")}
                name={["totalStopLoss"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("position.fields.totalTakeProfit")}
                name={["totalTakeProfit"]}
            >
                <Input />
            </Form.Item>
            <Form.Item name={["id"]} >
                <Input type="hidden" />
            </Form.Item>
        </Form>
    );
};
