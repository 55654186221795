import { useTable } from '@refinedev/antd';
import { useInvalidate } from '@refinedev/core';
import React, { useEffect } from 'react';
import { Account } from '../../../interfaces/account';
import { OpenOrdersTable } from '../../orders/openOrdersTable';
import { useOutletContext } from 'react-router-dom';


export const AccountOpenOrders: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const { tableProps } = useTable({
        resource: 'OpenOrders',
        meta: {
            query: { accountId: account.id },
            endpoint: `UserActions/${account.id}/OpenOrders`
        }
    });
    const invalidate = useInvalidate();

    useEffect(() => {
        const interval = setInterval(() => {
            invalidate({
                resource: 'OpenOrders',
                invalidates: ["detail"],
                id: account.id
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <OpenOrdersTable tableProps={tableProps} internal={true} withIdentity={false} />
    );
};

AccountOpenOrders.displayName = 'AccountOpenOrders';