import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Col, DatePicker, Form, FormProps, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { defaultFormLayout } from "../../constants";
import { AssetClassesEnum, OrderSide, OrderType, Pair } from "../../interfaces";
import { getEnumOptions, getGoodUntilOptions, getTypeByDate, GoodUntilTypes, setGoodUntil, setGoodUntilValueAndType } from "../../utils";

interface OrderFormProps {
    create?: boolean;
    formProps: FormProps;
};

export const OrderForm: React.FC<OrderFormProps> = ({ formProps, create }) => {
    const translate = useTranslate();

    const [goodUntilType, setGoodUntilType] = useState(0);
    const [isFX, setIsFX] = useState(false);

    const typeValue = Form.useWatch('type', formProps.form);
    const pairValue = Form.useWatch('pairId', formProps.form);

    useEffect(() => {
        if (formProps?.initialValues) {
            const type = getTypeByDate(formProps.initialValues.goodUntil, formProps);
            setGoodUntilType(type);
            formProps.initialValues.goodUntilType = getTypeByDate(formProps.initialValues.goodUntil, formProps);
            formProps.initialValues.goodUntilDate = dayjs(formProps.initialValues.goodUntil);

            const found = queryResult.data?.data.find((item) => item.id === pairValue);
            if (found) {
                setIsFX(found.assetClassId === AssetClassesEnum.FX);
            } else if (formProps.initialValues?.pair) {
                setIsFX(formProps.initialValues?.pair?.assetClassId === AssetClassesEnum.FX);
            }
        }
    }, [formProps?.initialValues, pairValue]);

    const priceToleranceOptionsFX = [
        { value: null, label: translate('enums.PriceTolerance.market') },
        { value: '1', label: '1 pip' },
        { value: '2', label: '2 pips' },
        { value: '3', label: '3 pips' },
        { value: '4', label: '4 pips' },
        { value: '5', label: '5 pips' },
        { value: '0', label: translate('enums.PriceTolerance.fillOrKill') }
    ];
    const priceToleranceOptionsOther = [
        { value: null, label: translate('enums.PriceTolerance.market') },
        { value: '1', label: '1 bp' },
        { value: '2', label: '2 bp' },
        { value: '3', label: '3 bp' },
        { value: '4', label: '4 bp' },
        { value: '5', label: '5 bp' },
        { value: '6', label: '6 bp' },
        { value: '7', label: '7 bp' },
        { value: '8', label: '8 bp' },
        { value: '9', label: '9 bp' },
        { value: '10', label: '10 bp' },
        { value: '0', label: translate('enums.PriceTolerance.fillOrKill') }
    ];

    const goodUntilOptions = getGoodUntilOptions(translate);

    const onGoodUntilChange = (value: number) => {
        setGoodUntilValueAndType(value, formProps, setGoodUntilType);
    };

    const priceToleranceOptions = isFX ? priceToleranceOptionsFX : priceToleranceOptionsOther;

    const { selectProps: pairSelectProps, queryResult } = useSelect<Pair>({
        resource: "pair",
        optionValue: "id",
        optionLabel: "id",
        queryOptions: {
            enabled: create ? true : false,
        },
        pagination: {
            pageSize: 10,
            mode: "server",
        },
    });

    const { selectProps: accountSelectProps } = useSelect({
        resource: "account",
        optionLabel: "displayName",
        queryOptions: {
            enabled: create ? true : false,
        },
        pagination: {
            pageSize: 10,
            mode: "server",
        },
    });

    const orderTypeOptions = getEnumOptions(OrderType, 'OrderType', translate, [OrderType.Unknown])
    const orderSideOptions = getEnumOptions(OrderSide, 'OrderSide', translate, [OrderSide.Unknown])

    const isNotMarket = typeValue != OrderType.Market;

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >

            {create && <Form.Item
                label={translate("order.fields.pairId")}
                name={["pairId"]}
                rules={[{ required: true, },]}
            >
                <Select {...pairSelectProps} />
            </Form.Item>}
            {create && <Form.Item
                label={translate("order.fields.account")}
                name={"accountId"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...accountSelectProps} />
            </Form.Item>}
            {create && <Form.Item
                label={translate("order.fields.type")}
                name={["type"]}
                rules={[{ required: true, },]}

            >
                <Select options={orderTypeOptions} />
            </Form.Item>}
            {create && <Form.Item
                label={translate("order.fields.side")}
                name={["side"]}
                rules={[{ required: true, },]}
            >
                <Select options={orderSideOptions} />
            </Form.Item>}
            {typeValue === OrderType.Market && <Form.Item
                label={translate("order.fields.priceTolerance")}
                name={["priceTolerance"]}
            >
                <Select options={priceToleranceOptions} />
            </Form.Item>}

            {isNotMarket && <Form.Item
                label={translate("order.fields.triggerPrice")}
                name={["triggerPrice"]}
            >
                <Input />
            </Form.Item>}

            <Form.Item
                label={translate("order.fields.qty")}
                name={["qty"]}
                rules={[{ required: true, },]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("order.fields.totalStopLoss")}
                name={["stopLossTotal"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("order.fields.totalTakeProfit")}
                name={["takeProfitTotal"]}
            >
                <Input />
            </Form.Item>
            {isNotMarket &&
                <Row gutter={8}>
                    <Col sm={8}>
                        <Form.Item
                            label={translate("order.fields.goodUntil")}
                            name={["goodUntilType"]}
                        >
                            <Select
                                options={goodUntilOptions}
                                onChange={onGoodUntilChange}
                            />
                        </Form.Item>
                    </Col>
                    {goodUntilType === GoodUntilTypes.GTT &&
                        <Col>
                            <Form.Item
                                name={["goodUntilDate"]}
                            >
                                <DatePicker
                                    format="DD/MM/YYYY hh:mm A"
                                    onChange={(date) => setGoodUntil(formProps, date)}
                                    showTime={{ use12Hours: true }}
                                    disabledDate={(current) => current && current < dayjs()}
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
            }


            <Form.Item
                name={["goodUntil"]}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name={["id"]} >
                <Input type="hidden" />
            </Form.Item>
        </Form>
    );
};
