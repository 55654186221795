import {
  useTable
} from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useTranslate
} from "@refinedev/core";
import { Table } from "antd";
import { List } from "../../components/crud/list";

import { ColumnsType } from "antd/es/table";
import React from "react";
import { EnumField, LogoPairField, NumberField } from "../../components/fields";
import { PositionSide } from "../../interfaces";
import { PositionSummaryLine } from "../../interfaces/marketbook";
import { OpenPositionsTable } from "./openPositionsTable";

const InternalTable: React.FC<{ pairId: string, side: PositionSide }> = ({ pairId, side }) => {
  const { tableProps } = useTable({
    resource: 'openpositions',
    syncWithLocation: false,
    meta: {
      query: { pairId, side },
    }
  });

  return <OpenPositionsTable tableProps={tableProps} withIdentity={true} internal={true} />
}

export const OpenPositionList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { tableProps } = useTable<PositionSummaryLine>({
    resource: 'openpositionssummary',
    pagination: { mode: 'off' }
  });

  const columns = [
    {
      title: translate("position.fields.pair"),
      dataIndex: 'pairId',
      key: 'pairId',
      render: (value: string) => <LogoPairField pairId={value} />
    },
    {
      title: translate("position.fields.side"),
      dataIndex: 'side',
      key: 'side',
      render: (value: number) => <EnumField enum="PositionSide" value={value} translate={translate} />
    },
    {
      title: translate("position.fields.totalNotional"),
      dataIndex: 'totalNotional',
      key: 'totalNotional',
      align: 'end',
      render: (value: number) => <NumberField value={value} />
    },
    {
      title: translate("position.fields.totalNumber"),
      dataIndex: 'totalNumber',
      align: 'end',
      key: 'totalNumber', render: (value: number) => <NumberField value={value} />
    },
    {
      title: translate("position.fields.distinctAccounts"),
      dataIndex: 'distinctAccounts',
      key: 'distinctAccounts',
      align: 'end',
      render: (value: number) => <NumberField value={value} />
    },
  ] as ColumnsType<PositionSummaryLine>;

  const expandedRowRender = (record: PositionSummaryLine) => {
    return <InternalTable pairId={record.pairId} side={record.side} />;
  };

  const getRowClassName = (record: PositionSummaryLine) => {
    return 'default-action-show clickable-row position-' + (record.side === PositionSide.Long ? 'long' : 'short');
  }

  return (
    <List autorefresh={true} resource="openpositionssummary">
      <Table
        rowClassName={(record: PositionSummaryLine) => getRowClassName(record)}
        rowKey={(record: PositionSummaryLine) => record.side + record.pairId}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record as PositionSummaryLine),
          expandRowByClick: true
        }}
        {...tableProps}
      />
    </List>
  );
};
