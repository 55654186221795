import {
    DeleteButton, EditButton,
    ShowButton, TextField, useTable
} from "@refinedev/antd";
import {
    BaseRecord, IResourceComponentsProps, useTranslate
} from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
import { List } from "../../components/crud/list";
import { EnumField, UnitValueField, NumberField } from "../../components/fields";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { FeeChargeTiming, Override } from "../../interfaces";
import { getEnumFilter, getFixedLengthNumber } from "../../utils";


const getNumericRenderer = (precision: number) => {
    const numericRender = (value: number | undefined | null) => {
        return <NumberField value={value} digits={precision} />
    }
    return numericRender;
}

const getPercentRenderer = (precision: number) => {
    const numericRender = (value: number | undefined | null) => {
        if (value !== undefined && value !== null) {
            return <UnitValueField value={value*100} unit="%" digits={precision} />
        }
        return value;
    }
    return numericRender;
}

export const RatioLeverage: React.FC<{ ratio: number, maxRatioLength: number, maxLeverageLength: number }> = ({ ratio, maxRatioLength, maxLeverageLength }) => {
    const ratioDisplay = getFixedLengthNumber(ratio, maxRatioLength, 2);
    const leverage = getFixedLengthNumber(1 / ratio, maxLeverageLength, 0);
    return <div className="text-number leverage"><span className="left">{ratioDisplay}</span><span className="right"> (1:{leverage})</span></div>
}


export const OverrideList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { search, listProps } = useSearchList();
    const query: Record<string, string> = {};
    if (search) {
        query.descriptionLIKE = search; // TODO: search
        query.tokenLIKE = search; // TODO: search
    }

    const { tableProps } = useTable<Override>({
        syncWithLocation: true,
        hasPagination: false,
        meta: { query }
    });

    // TODO: calculate these numbers
    const maxRatioLength = 3;
    const maxLeverageLength = 2;

    const clickTableProps = useTableClick({ resource: 'override' });
    const chargeTimeFilter = getEnumFilter(FeeChargeTiming, 'FeeChargeTiming', translate, [FeeChargeTiming.Unknown]);

    return (
        <List {...listProps} resource="override">
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    rowScope="rowgroup"
                    dataIndex="token"
                    title={translate("override.fields.token")}
                />
                <Table.Column
                    align="right"
                    width={130}
                    sorter={true}
                    dataIndex="orderFeeFloatingBuy"
                    title={translate("override.fields.orderFeeFloatingBuy")}
                    render={getPercentRenderer(2)}
                />
                <Table.Column
                    align="right"
                    sorter={true}
                    width={130}
                    dataIndex="orderFeeFloatingSell"
                    title={translate("override.fields.orderFeeFloatingSell")}
                    render={getPercentRenderer(2)}
                />
                <Table.Column
                    align="right"
                    sorter={true}
                    width={130}
                    dataIndex="orderFeeFixedBuy"
                    title={translate("override.fields.orderFeeFixedBuy")}
                    render={getNumericRenderer(2)}
                />
                <Table.Column
                    align="right"
                    sorter={true}
                    width={130}
                    dataIndex="orderFeeFixedSell"
                    title={translate("override.fields.orderFeeFixedSell")}
                    render={getNumericRenderer(2)}
                />
                <Table.Column
                    width={150}
                    sorter={true}
                    dataIndex="chargeTiming"
                    filters={chargeTimeFilter}
                    title={translate("override.fields.chargeTiming")}
                    render={(value) => <EnumField enum='FeeChargeTiming' translate={translate} value={value} />}
                />
                <Table.Column
                    align="right"
                    width={100}
                    sorter={true}
                    dataIndex="marginRatioLong"
                    title={translate("override.fields.marginRatioLong")}
                    render={(value) => <RatioLeverage ratio={value} maxRatioLength={maxRatioLength} maxLeverageLength={maxLeverageLength} />}
                />
                <Table.Column
                    align="right"
                    width={130}
                    sorter={true}
                    dataIndex="marginRatioShort"
                    title={translate("override.fields.marginRatioShort")}
                    render={(value) => <RatioLeverage ratio={value} maxRatioLength={maxRatioLength} maxLeverageLength={maxLeverageLength} />}
                />
                <Table.Column
                    align="right"
                    width={100}
                    sorter={true}
                    dataIndex="quoteSpreadAffectAsk"
                    title={translate("override.fields.quoteSpreadAffectAsk")}
                    render={(value) => <UnitValueField value={value * 100} digits={2} unit={'%'} />}
                />
                <Table.Column
                    align="right"
                    width={130}
                    sorter={true}
                    dataIndex="quoteSpreadAffectBid"
                    title={translate("override.fields.quoteSpreadAffectBid")}
                    render={(value) => <UnitValueField value={value} digits={2} unit={'%'} />}
                />
                <Table.Column
                    dataIndex="description"
                    title={translate("override.fields.description")}
                />
                <Table.Column
                    width={130}
                    align="right"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
