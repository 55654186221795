// import "./wdyr"

import React from "react";
import { createRoot } from "react-dom/client";
import './css/index.css';

import App from "./App";
import "./i18n";

import * as Sentry from "@sentry/react";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc);
dayjs.extend(localizedFormat)

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", import.meta.env.VITE_BACKEND_URL, import.meta.env.VITE_AUTH_URL],
//     }),
//     new Sentry.Replay(),
//     new Sentry.Integrations.Breadcrumbs({ console: false })
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  // TODO: Uncomment for prduction
  // <React.StrictMode>
  <React.Suspense fallback="loading">
    <App />
  </React.Suspense>
  // </React.StrictMode>
);
