import React, { useEffect } from 'react';

import { Account } from '../../../interfaces/account';
import { OpenPositionsTable } from '../../position/openPositionsTable';
import { useInvalidate, useOne } from '@refinedev/core';
import { TableProps } from 'antd';
import { Position } from '../../../interfaces';
import { useOutletContext } from 'react-router-dom';

export const AccountOpenPositions: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const { data, isLoading } = useOne({
        resource: 'OpenPosition',
        id: account.id,
        meta: {
            query: { accountId: account.id },
            endpoint: `UserActions/${account.id}/OpenPositions`
        }
    });
    const invalidate = useInvalidate();

    useEffect(() => {
        const interval = setInterval(() => {
            invalidate({
                resource: 'OpenPosition',
                invalidates: ["detail"],
                id: account.id
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const tableProps = {
        dataSource: data?.data?.positions,
        isLoading: isLoading,
    } as TableProps<Position>;

    return (
        <OpenPositionsTable tableProps={tableProps} withIdentity={false} internal={false} />
    );

};