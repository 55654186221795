import {
    DeleteButton,
    EditButton,
    ShowButton
} from "@refinedev/antd";
import {
    BaseRecord,
    useMany,
    useTranslate
} from "@refinedev/core";
import { Space, Table } from "antd";
import { TableProps } from "antd/lib";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React from "react";
import { Link } from "react-router-dom";
import { BooleanField, IdentityField, NumberField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useArrayFilter } from "../../hooks/useArrayFilter";
import { useReferenceFilter } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { Account } from "../../interfaces";

interface AccountTableProps extends TableProps<Account> {
    showOwner: boolean;
}

export const AccountsTable: React.FC<AccountTableProps> = (props) => {

    const translate = useTranslate();

    const { data: accountGroupData } =
        useMany({
            resource: "accountGroup",
            ids: []
        });

    const assetFilters = useArrayFilter({ resource: 'reference', meta: { endpoint: 'account/reference/denominatedInAssetId' } })
    const {filters: userFilters} = useReferenceFilter({ enabled: props.showOwner, resource: 'user', search: { resource: 'reference', meta: { endpoint: 'account/reference/ownerId' } }, labelName: 'fullName' })

    const clickTableProps = useTableClick({ resource: 'account', action: 'show', meta: { tab: 'info' } });

    return <Table<Account> {...props} rowKey="id" {...clickTableProps}>
        <Table.Column
            dataIndex="token"
            title={translate("account.fields.token")}
            render={(value: string, record: Account) => <AccountField value={record} link="info" showCurrency={false} showIdentity={false} />}
        />
        <Table.Column
            dataIndex={["accountGroupId"]}
            title={translate("account.fields.accountGroup")}
            filters={accountGroupData?.data?.map((item) => { return { text: item.name, value: item.id } as ColumnFilterItem })}
            sorter={true}
            render={(value, record: BaseRecord) => <>{record.accountGroup.name}</>}
        />
        {props.showOwner && <Table.Column
            dataIndex={["ownerId"]}
            title={translate("account.fields.owner")}
            render={(value, record: BaseRecord) => <Link to={`/user/show/${record.ownerId}/details`}><IdentityField value={record.identity} hideCurrency={true} /></Link>}
            filters={userFilters}
            filterSearch={true}
            sorter={true}
        />}
        <Table.Column
            dataIndex={["denominatedInAssetId"]}
            title={translate("account.fields.denominatedInAsset")}
            filters={assetFilters}
            sorter={true}
        />
        <Table.Column
            align="end"
            dataIndex="currentBalance"
            title={translate("account.fields.currentBalance")}
            render={(value: number, record: BaseRecord) => <NumberField value={value} digits={record.denominatedInAsset?.displayDecimals} />}
            sorter={true}
        />
        <Table.Column
            align="end"
            dataIndex="reservedAmount"
            title={translate("account.fields.reservedAmount")}
            render={(value: number, record: BaseRecord) => <NumberField value={value} digits={record.denominatedInAsset?.displayDecimals} />}
            sorter={true}
        />
        <Table.Column
            dataIndex={["enabled"]}
            title={translate("account.fields.enabled")}
            render={(value: boolean) => <BooleanField value={value} />}
            sorter={true}
        />
        <Table.Column
            align="end"
            width={100}
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
                <Space>
                    <EditButton
                        hideText
                        size="small"
                        resource="account"
                        recordItemId={record.id}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        resource="account"
                        meta={{ tab: 'info' }}
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        resource="account"
                        recordItemId={record.id}
                    />
                </Space>
            )}
        />
    </Table>
}
