import {AccountGroup} from "./accountGroup";
import { Asset } from "./asset";
import { LyraSequential, UserIdentity } from "./base";
import { Override } from "./override";
import {User} from "./user";


export interface Account extends LyraSequential {
    defaultAccount: boolean;
    enabled: boolean;
    currentBalance: number;
    reservedAmount: number;
    overrideFeeId: number;
    overrideFee: Override;
    accountGroup: AccountGroup;
    accountGroupId: number;
    denominatedInAssetId: string;
    denominatedInAsset: Asset;
    ownerId: number;
    owner: User;
    identity: UserIdentity;
}

export class AccountData implements Account {
    id: number;
    token: string;
    defaultAccount: boolean;
    enabled: boolean;
    currentBalance: number;
    reservedAmount: number;
    overrideFeeId: number;
    overrideFee: any;
    accountGroup: any;
    accountGroupId: any;
    denominatedInAssetId: any;
    denominatedInAsset: any;
    ownerId: any;
    owner: any;
    identity: UserIdentity;

    constructor(
        account: Account
    ) {
        this.id = account.id;
        this.token = account.token;
        this.defaultAccount = account.defaultAccount;
        this.enabled = account.enabled;
        this.currentBalance = account.currentBalance;
        this.reservedAmount = account.reservedAmount;
        this.overrideFeeId = account.overrideFeeId;
        this.overrideFee = account.overrideFee;
        this.accountGroup = account.accountGroup;
        this.accountGroupId = account.accountGroupId;
        this.denominatedInAssetId = account.denominatedInAssetId;
        this.denominatedInAsset = account.denominatedInAsset;
        this.ownerId = account.ownerId;
        this.owner = account.owner;
        this.identity = account.identity;
    }

    get displayName(): string {
        return `${this.identity.denominatedIn}: ${this.identity.fullName}`;
    }
}