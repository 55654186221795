import React, { ReactElement } from "react";

import { RefineFieldCommonProps } from "@refinedev/ui-types";
import { NumberField, NumberFieldPropsExt } from "./number";


type UnitFiledProps = NumberFieldPropsExt & {
    unit?: string;
}


export const UnitValueField: React.FC<UnitFiledProps> = (props) => {
    const className = props.className || '';
    if (props.value === null || props.value === undefined) {
        return null;
    }
    return (
        <span className={className + " unit-field"}><NumberField {...props} className="text-number" /> <span className="unit">{props.unit}</span></span>
    );
};
