import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { OrderForm } from "./form";
import { Order } from "../../interfaces";
import { Edit } from "../../components/crud/edit";
import { useTitleData } from "../../contexts/title";

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
    
    const { formProps, saveButtonProps, queryResult } = useForm<Order>(
        {
            resource: "order",
            action: "edit",
            mutationMeta: {
                endpoint: `useractions/updateorder`,
            },
            warnWhenUnsavedChanges: true,
            invalidates: ["list", "detail"]            
        }
    );
    useTitleData({ ...formProps.initialValues });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <OrderForm formProps={formProps} />
        </Edit>
    );
};
