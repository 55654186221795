import React from "react";
import { FormProps, TimeRangePickerProps } from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useEffect } from "react";
import { BaseRecord } from "@refinedev/core";
import { AssetClassesEnum, AssetClassesEnumToString } from "./interfaces";

type SelectOption = { value: number | string; label: string };

export const EnumToObject = (enm: object) => {
    const obj = Object.entries(enm)
        .filter(([k]) => isNaN(Number(k)))
        .map(([k, v]) => [Number(v), String(k)]);

    return new Map<number, string>(obj as Array<[number, string]>);
};


export const getBooleanTableFilter = (translate: CallableFunction) => {
    return [
        { text: translate('common.boolean.yes'), value: true },
        { text: translate('common.boolean.no'), value: false }
    ]
}

const getEnumKeyValues = (enumObject: object, name: string, translate: CallableFunction, exclude: string[] | number[] = [], include: string[] | number[] = [], keyName = 'value', valueName = 'label', numbers = false) => {
    return Object.entries(enumObject)
        .filter(([key, value]) => {
            if (!isNaN(Number(key))) {
                return false;
            }
            if (include.length > 0) {
                return include.includes(value as never);
            }
            return !exclude.includes(value as never);
        })
        .map(([key, value]) => { return { [keyName]: numbers ? Number(value) : String(value), [valueName]: translate(`enums.${name}.${key}`) } });
}

export const getEnumOptions = (enumObject: object, name: string, translate: CallableFunction, exclude: string[] | number[] = [], include: string[] | number[] = []): SelectOption[] => {
    return getEnumKeyValues(enumObject, name, translate, exclude, include, 'value', 'label', false) as SelectOption[];
}

export const getEnumNumericOptions = (enumObject: object, name: string, translate: CallableFunction, exclude: number[] = [], include: number[] = []): SelectOption[] => {
    return getEnumKeyValues(enumObject, name, translate, exclude, include, 'value', 'label', true) as SelectOption[];
}

export const getEnumFilter = (enumObject: object, name: string, translate: CallableFunction, exclude: string[] | number[] = [], include: string[] | number[] = []): ColumnFilterItem[] => {
    return getEnumKeyValues(enumObject, name, translate, exclude, include, 'value', 'text', false) as ColumnFilterItem[];
}

export const getEnumNumericFilter = (enumObject: object, name: string, translate: CallableFunction, exclude: string[] | number[] = [], include: string[] | number[] = []): ColumnFilterItem[] => {
    return getEnumKeyValues(enumObject, name, translate, exclude, include, 'value', 'text', true) as ColumnFilterItem[];
}

export const formatNumberNegativeRound = (value: number, digits: number) => {
    value = Math.round(value / Math.pow(10, -digits)) * Math.pow(10, -digits);
    return new Intl.NumberFormat(navigator.language).format(value);
}

export const formatNumberTrim = (value: number, digits = 3) => {
    if (digits < 0) {
        return formatNumberNegativeRound(value, digits);
    }
    return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: digits }).format(value);
}

export const formatNumberFix = (value: number, digits = 3) => {
    if (digits < 0) {
        return formatNumberNegativeRound(value, digits);
    }
    return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(value);
}

export const getFixedLengthNumber = (value: number, maxLength: number, precision = 2) => {
    const dif = maxLength - Math.round(value).toString().length;
    if (dif > 0) {
        return '⠀'.repeat(dif) + formatNumberFix(value, precision);
    } else {
        return formatNumberFix(value, precision);
    }
}

export const GoodUntilTypes = {
    GTC: 0,
    GTD: 1,
    GTT: 2
};

export function getGoodUntilOptions(translate: CallableFunction) {
    return [
        { value: GoodUntilTypes.GTC, label: translate('enums.GoodUntilFull.GTC') },
        { value: GoodUntilTypes.GTD, label: translate('enums.GoodUntilFull.GTD') },
        { value: GoodUntilTypes.GTT, label: translate('enums.GoodUntilFull.GTT') },
    ]
};

export const getGoodUntil = (data: number) => {
    if (data === GoodUntilTypes.GTD) {
        return dayjs().endOf('day').toISOString();
    }
    if (data === GoodUntilTypes.GTC) {
        return new Date(2100, 1, 1).toISOString();
    }
};

export const getTypeByDate = (data: Date | null, formProps: FormProps) => {
    if (!data) {
        return GoodUntilTypes.GTC;
    }
    const date = formProps?.initialValues?.goodUntil;
    const mDate = dayjs(date);
    if (mDate.year() === 2100) {
        return GoodUntilTypes.GTC;
    }
    const eod = dayjs().endOf('day').toISOString().slice(0, 16);
    if (eod === date.slice(0, 16)) {
        return GoodUntilTypes.GTD;
    }
    return GoodUntilTypes.GTT;
};

export const setGoodUntilValueAndType = (value: number, formProps: FormProps, setGoodUntilType: CallableFunction) => {
    switch (value.toString()) {
        case GoodUntilTypes.GTC.toString():
            formProps.form?.setFieldsValue({ goodUntil: getGoodUntil(value) });
            setGoodUntilType(GoodUntilTypes.GTC);
            break;
        case GoodUntilTypes.GTD.toString():
            formProps.form?.setFieldsValue({ goodUntil: getGoodUntil(value) });
            setGoodUntilType(GoodUntilTypes.GTD);
            break;
        case GoodUntilTypes.GTT.toString():
            formProps.form?.setFieldsValue({ goodUntilDate: dayjs().add(1, 'day') });
            setGoodUntilType(GoodUntilTypes.GTT);
            break;
    }
};

export function setGoodUntil(formProps: FormProps, date: dayjs.Dayjs) {
    formProps.form?.setFieldValue('goodUntil', date?.toISOString());
};


export function dataToFilter(data: BaseRecord[] | undefined, prefix: string, key: string = 'id', text: string = 'name') {
    if (!data) return [];
    return data.map((item: BaseRecord) => {
        return { text: item[text], value: `${prefix}:${item[key]}` }
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LogComponent: React.FC<{ children: any }> = ({ children }) => {
    useEffect(() => {
        console.log(children);
    }, [children]);

    return null;
};


export const getRangePresets = (translate: CallableFunction) => {
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Last Hour', value: [dayjs().add(-1, 'h'), dayjs()] },
        { label: 'Last Day', value: [dayjs().add(-1, 'd'), dayjs()] },
        { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    ];

    return rangePresets;
}

export const CategoryLabelFormatters = {
    pair: (name: string) => name,
    asset: (name: string) => name,
    assetClass: (name: string) => AssetClassesEnumToString(parseInt(name) as AssetClassesEnum),
}
