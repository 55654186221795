import { useOne, useTranslate } from "@refinedev/core";
import { Button, Card, Col, DatePicker, Row, Space, Spin, Typography } from "antd";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ExposureEntryMetrics, ExposureEntryMetricsReport } from "../../../../interfaces";
import { formatNumberFix, getRangePresets } from "../../../../utils";


const nowTime = dayjs();
const backTime = nowTime.subtract(1, 'hour');
const { RangePicker } = DatePicker;
const { Title } = Typography;

export const RisksHistoryComponent: React.FC = () => {
    const translate = useTranslate();
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([backTime, nowTime]);
    const rangePresets = getRangePresets(translate);


    const dateFormat = 'YYYY-MM-DD HH:mm';
    const { data: exposureHistoryData, isLoading } = useOne<ExposureEntryMetricsReport>({
        resource: 'entryHistory',
        queryOptions: { enabled: true, },
        meta: {
            query: {
                from: dateRange[0].utc().format(dateFormat),
                to: dateRange[1].utc().format(dateFormat),
            }
        }
    });

    const { series, labels } = useMemo(() => {
        const retLabels = new Array<string>();
        const seriesData = {
            rates: [
                { name: translate('var.fields.sharpe'), data: new Array<number>() },
                { name: translate('var.fields.sortino'), data: new Array<number>() },
            ],
            risk: [
                { name: translate('var.fields.var'), data: new Array<number>() },
                { name: translate('var.fields.cvar'), data: new Array<number>() },
            ],
            total: [
                { name: translate('var.fields.totalValue'), data: new Array<number>() },
                { name: translate('var.fields.internalizedValue'), data: new Array<number>() },
                { name: translate('var.fields.accCurrNetPosition'), data: new Array<number>() },
            ],
            positions: [
                { name: translate('var.fields.positions'), data: new Array<number>() },
            ],
        };

        exposureHistoryData?.data.history.forEach((report: ExposureEntryMetrics) => {
            retLabels.push(report.snapshot);
            seriesData.rates[0].data.push(report.sharpeRatio);
            seriesData.rates[1].data.push(report.sortinoRatio);

            seriesData.risk[0].data.push(report.vaR);
            seriesData.risk[1].data.push(report.cVaR);

            seriesData.total[0].data.push(report.totalValue);
            seriesData.total[1].data.push(report.internalizedValue);
            seriesData.total[2].data.push(report.accCurrNetPosition);

            seriesData.positions[0].data.push(report.positionCount);
        });

        ApexCharts.exec('history-rates', 'updateSeries', seriesData.rates);
        ApexCharts.exec('history-risks', 'updateSeries', seriesData.risk);
        ApexCharts.exec('history-totals', 'updateSeries', seriesData.total);
        ApexCharts.exec('history-positions', 'updateSeries', seriesData.positions);

        return { series: seriesData, labels: retLabels };
    }, [exposureHistoryData?.data, dateRange]);


    const [ratesOptions, riskOptions, totalsOptions, positionsOptions] = useMemo(() => {
        const formatters = {
            'history-rates': (val: number) => formatNumberFix(val, 2),
            'history-risks': (val: number) => formatNumberFix(val, 0),
            'history-totals': (val: number) => formatNumberFix(val, 0),
            'history-positions': (val: number) => formatNumberFix(val, 0),
        }
        const options: ApexOptions = {
            chart: {
                background: '#202024',
                group: `risk-history`,
                toolbar: {
                    show: false
                },
            },
            grid: {
                borderColor: '#121214',
            },
            theme: { mode: "dark", palette: "palette6" },
            legend: {
                show: true,
            },
            stroke: {
                width: 2,
            },
            labels: labels,
            xaxis: {
                categories: labels,
                type: 'datetime',
                labels: {
                    formatter: function (value) {
                        return value ? dayjs(value).local().format('HH:mm MMM DD') : '';
                    }
                }
            },
        };

        const ratesOptions = { ...options, chart: { ...options.chart, id: `history-rates` }, yaxis: { forceNiceScale: true, labels: { formatter: formatters['history-rates'] } } };
        const riskOptions = { ...options, chart: { ...options.chart, id: `history-risks` }, yaxis: { forceNiceScale: true, labels: { formatter: formatters['history-risks'] } } };
        const totalsOptions = { ...options, chart: { ...options.chart, id: `history-totals` }, yaxis: { forceNiceScale: true, labels: { formatter: formatters['history-totals'] } } };
        const positionsOptions = { ...options, chart: { ...options.chart, id: `history-positions` }, yaxis: { forceNiceScale: true, labels: { formatter: formatters['history-positions'] } } };

        return [ratesOptions, riskOptions, totalsOptions, positionsOptions];

    }, [labels]);


    const selector = <Space size={10}><Space size={6}>{rangePresets.map((range, index) => <Button key={index} onClick={() => setDateRange(range.value as never)}>{range.label}</Button>)}</Space>
        <RangePicker presets={rangePresets} showTime value={dateRange} onChange={(a1) => a1 && setDateRange([a1[0]!, a1[1]!])} /></Space>;

    return <Spin spinning={isLoading}>
        <Card title={<Title level={3}>{translate('var.fields.history')}</Title>} extra={selector}>
            <Row gutter={[16, 16]}>
                <Col sm={12}>
                    <Card size="small" title={translate('var.fields.ratios')} style={{ width: '100%' }}>
                        <ReactApexChart options={ratesOptions} series={series.rates} type="line" width="100%" height={"250px"} />
                    </Card>
                </Col>
                <Col sm={12}>
                    <Card size="small" title={translate('var.fields.risk', { currency: exposureHistoryData?.data.denominatedIn.accountingCurrency })}>
                        <ReactApexChart options={riskOptions} series={series.risk} type="line" width="100%" height={"250px"} />
                    </Card>
                </Col>
                <Col sm={12}>
                    <Card size="small" title={translate('var.fields.totals', { currency: exposureHistoryData?.data.denominatedIn.accountingCurrency })}>
                        <ReactApexChart options={totalsOptions} series={series.total} type="line" width="100%" height={"250px"} />
                    </Card>
                </Col>
                <Col sm={12}>
                    <Card size="small" title={translate('var.fields.positions')}>
                        <ReactApexChart options={positionsOptions} series={series.positions} type="line" width="100%" height={"250px"} />
                    </Card>
                </Col>
            </Row>
        </Card>
    </Spin >;
};
