import { useTranslate } from "@refinedev/core";
import { Col, Form, FormProps, Input, Row, Select, InputNumber } from "antd";
import React from "react";
import { defaultFormLayout } from "../../constants";

export const OverrideForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
            labelCol={{ flex: '150px' }}
        >
            <Row>
                <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                        label={translate("override.fields.orderFeeFloatingBuy")}
                        name={["orderFeeFloatingBuy"]}
                        rules={[
                            {
                                type: "number",
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.orderFeeFloatingSell")}
                        name={["orderFeeFloatingSell"]}
                        rules={[
                            {
                                type: "number",
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.orderFeeFixedBuy")}
                        name={["orderFeeFixedBuy"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.orderFeeFixedSell")}
                        name={["orderFeeFixedSell"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label={translate("override.fields.quoteSpreadAffectAsk")}
                        name={["quoteSpreadAffectAsk"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.quoteSpreadAffectBid")}
                        name={["quoteSpreadAffectBid"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                    <Form.Item
                        label={translate("override.fields.chargeTiming")}
                        name={["chargeTiming"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                { value: 'OpenOnly', label: translate('override.chargeTiming.OpenOnly') },
                                { value: 'CloseOnly', label: translate('override.chargeTiming.CloseOnly') },
                                { value: 'OpenAndClose', label: translate('override.chargeTiming.OpenAndClose') },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.marginRatioLong")}
                        name={["marginRatioLong"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.marginRatioShort")}
                        name={["marginRatioShort"]}
                        rules={[
                            {
                                required: true,
                                type: "number",
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label={translate("override.fields.description")}
                        name={["description"]}
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
